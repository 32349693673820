import { api } from './api';

const get = async () => {
  try {
    const { data } = await api.get(`/userProfile`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getByKey = async key => {
  try {
    const { data } = await api.get(`/userProfile/byKey/${key}`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const update = async (id, datas) => {
  try {
    const { data } = await api.put(`/userProfile/${id}`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const create = async datas => {
  try {
    const { data } = await api.post(`/userProfile`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  get,
  getByKey,
  create,
  update
};

export const adminRights = {
  funnel: {
    funnel: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    data: {
      update: false,
      compute: false
    },
    medias: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    models: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    backOfficeCsvExport: {
      read: false
    },
    properties: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    funnelStep: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    yougovMetrics: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    anomalies: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    connectors: {
      create: false,
      read: false,
      update: false,
      delete: false,
      refresh: false
    },
    dataFeedback: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    alert: {
      create: false,
      read: false,
      update: false,
      delete: false
    }
  },
  activation: {
    create: false,
    read: false,
    update: false,
    delete: false
  },
  campaign: {
    campaign: {
      create: false,
      read: false,
      update: false,
      delete: false
    }
  },
  audiences: {
    criteria: {
      create: false,
      read: false,
      update: false
    },
    categories: {
      create: false,
      read: false,
      update: false
    },
    tagFunnel: {
      read: false,
      update: false
    },
    activatedAudience: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    exportSegment: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    partners: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    exportMedia: {
      create: false,
      read: false,
      update: false,
      delete: false
    }
  },
  logs: {
    read: false
  },
  general: {
    dashboard: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    customers: {
      create: false,
      read: false,
      update: false,
      delete: false,
      advanced: false
    },
    tarification: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    groups: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    users: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    connection: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    connector: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    admin: {
      create: false,
      update: false,
      delete: false
    },
    adminRights: {
      update: false
    },
    domain: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    activableMedias: {
      create: false,
      read: false,
      update: false
    }
  },
  ramses: {
    reports: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    properties: {
      create: false,
      read: false,
      update: false,
      delete: false
    }
  },
  pml: {
    config: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    shop: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    shopGroup: {
      create: false,
      read: false,
      update: false,
      delete: false
    },
    newsletter: {
      send: false
    }
  },
  content: {
    formats: {
      create: false,
      read: false,
      update: false,
      delete: false
    }
  },
  godMode: {
    read: false
  }
};

export default adminRights;

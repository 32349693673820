import React, { useEffect } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { useNavigation } from 'modules/Contents/contexts/NavigationContext';

import SidebarContent, { SideItemContent } from './components/SidebarContent';

/**
 * SidebarContainer
 * @container
 * @component
 *
 */

const SidebarContainer = () => {
  const { themeColors } = useTheme();
  const { menu, setMenu } = useNavigation();
  const { adminRights } = useUser();
  const linkInfo = [
    {
      name: 'configFormats',
      surname: 'Configuration des formats par marques',
      enabled: adminRights?.content?.formats?.read || false
    }
  ];

  const updateMenu = linkName => {
    // Set menu only if it's different by actual menu
    if (menu !== linkName) {
      setMenu(linkName);
    }
  };

  useEffect(() => {
    updateMenu(linkInfo[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <SidebarContent>
      {linkInfo.map(link => {
        return (
          <SideItemContent
            key={link.name}
            active={link.name === menu.name}
            svgAttrs={{
              fill: themeColors.icon,
              dataStroke: themeColors.icon,
              stroke: themeColors.icon
            }}
            subtitle={link.subtitle}
            title={link.surname.toUpperCase()}
            hasActiveClass={link.name === menu.name}
          />
        );
      })}
    </SidebarContent>
  );
};

export default SidebarContainer;

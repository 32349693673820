import React from 'react';
import PropTypes from 'prop-types';

import { panels } from 'utils/constants';

import BaseSidebar from './style';

/**
 * SidebarAudience
 * @component
 *
 */
function SidebarContent(props) {
  const { children } = props;
  return <BaseSidebar panel={panels.backoffice}>{children}</BaseSidebar>;
}
SidebarContent.propTypes = {
  children: PropTypes.node.isRequired
};

export { SideItemContent } from './style';
export default SidebarContent;

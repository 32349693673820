import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import { ACTIVATION } from 'utils/rest';

import { Text, Tooltip } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components';

const ConstraintContainer = () => {
  const { currentPresetId, canEdit } = useNavigation();
  const [rows, setRows] = useState([]);
  const [constraints, setConstraintList] = useState([]);
  const [ranges, setRangesList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedConstraint, setSelectedConstraint] = useState({});
  const { themeColors } = useTheme();
  const { adminRights } = useUser();

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectConstraint = id => {
    const constraint = constraints.find(el => el._id === id);
    setSelectedConstraint(constraint);
  };
  const closeModal = () => {
    setModal(null);
    selectConstraint({});
  };
  const getConstraints = async () => {
    try {
      const result = await ACTIVATION.getConstraints(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getConstraintsRanges = async () => {
    try {
      const result = await ACTIVATION.getConstraintsContextualBetaRanges(
        currentPresetId
      );
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectConstraint(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = datas => {
    // manage ranges
    const toAdd = datas.ranges.filter(f => !ranges.find(e => e._id === f._id));
    const toDelete = ranges.filter(
      f => !datas.ranges.find(e => e._id === f._id)
    );

    const subList = toAdd.map(range =>
      ACTIVATION.createConstraintsContextualBetaRanges(range)
    );
    const criteriaList = toDelete.map(range =>
      ACTIVATION.deleteConstraintsContextualBetaRanges(range._id)
    );

    Promise.all([
      ...subList,
      ...criteriaList,
      ACTIVATION.updateConstraints(selectedConstraint._id, datas)
    ]).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  const formatConversionRates = conversionRates => {
    const list = Object.keys(conversionRates).map(key => {
      return { key, value: numeral(conversionRates[key]).format('0[.]00%') };
    });
    const nbConversionRates = list.length;
    if (nbConversionRates === 0) {
      return <Text>Aucun</Text>;
    }
    const str = ` ${nbConversionRates} taux de couverture`;

    const getToolTipText = txt => {
      return (
        <Row spacing={0}>
          <Item>
            <Text color={themeColors.dark}>{txt}</Text>
          </Item>
        </Row>
      );
    };

    const tooltipContent = () => {
      return (
        <Container>
          {list.map(item => getToolTipText(` ${item.key}: ${item.value} `))}
        </Container>
      );
    };

    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="activation">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const formatRanges = list => {
    const nbConversionRates = list.length;
    if (nbConversionRates === 0) {
      return <Text>Aucun</Text>;
    }
    const str = ` ${nbConversionRates} coefficients`;

    const getToolTipText = txt => {
      return (
        <Row spacing={0}>
          <Item>
            <Text color={themeColors.dark}>{txt}</Text>
          </Item>
        </Row>
      );
    };

    const tooltipContent = () => {
      return (
        <Container>
          {list.map(item =>
            getToolTipText(` [ ${item.min} - ${item.max} ]: ${item.value} `)
          )}
        </Container>
      );
    };

    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="activation">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const fetchData = async () => {
    const constraintsList = await getConstraints();
    const allRanges = await getConstraintsRanges();

    const betaRanges = allRanges.map(el => {
      const item = el;
      if (item.min === -1) {
        item.min = 0;
      }
      return item;
    });

    const toolTipRanges = formatRanges(betaRanges);
    const mapped = constraintsList.map(item => {
      const newItem = { ...item };
      newItem.beta = numeral(item.beta).format('0[.]00%');
      newItem.maximumDataCost = numeral(item.maximumDataCost).format('0[.]00%');
      newItem.defaultConversionRates = formatConversionRates(
        item.defaultConversionRates
      );
      newItem.ranges = toolTipRanges;
      return newItem;
    });
    setLoaded(true);
    setRangesList(betaRanges);
    setConstraintList(constraintsList);
    setRows(mapped);
  };

  useEffect(() => {
    if (!loaded) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [loaded]);

  return (
    <Container>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Beta utilisé pour le calcul du f-score',
                  id: 'beta'
                },
                {
                  label: 'Nombre de contacts par boite aux lettres',
                  id: 'contactsPerMailbox'
                },
                {
                  label: 'Taux de couverture par défaut',
                  id: 'defaultConversionRates'
                },
                {
                  label: 'Coefficient multiplicateur pour ISC contextuel',
                  id: 'ranges'
                },
                {
                  label: 'Frais data maximums',
                  id: 'maximumDataCost'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={canEdit && openUpdateModal}
              onView={!canEdit && openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ ...selectedConstraint, ranges }}
              actionName="Modifiers"
              canEdit={canEdit}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ConstraintContainer;

import { api } from './api';

const getInsightbyIds = async ids => {
  try {
    const { data } = await api.post('/insightUserTarget/ids', { ids });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  getInsightbyIds
};

import { api, bufferApi } from './api';

const getConstraints = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/constraints${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateConstraints = async (id, datas) => {
  try {
    const result = await api.put(`/v2/activation/constraints/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getConstraintsContextualBetaRanges = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/constraints/contextualBetaRanges${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateConstraintsContextualBetaRanges = async (id, datas) => {
  try {
    const result = await api.put(
      `/v2/activation/constraints/contextualBetaRanges/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const createConstraintsContextualBetaRanges = async datas => {
  try {
    const result = await api.post(
      `/v2/activation/constraints/contextualBetaRanges`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const deleteConstraintsContextualBetaRanges = async (id, datas) => {
  try {
    const result = await api.delete(
      `/v2/activation/constraints/contextualBetaRanges/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getConstraintsChannels = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/constraints/channels${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateConstraintsChannels = async (id, datas) => {
  try {
    const result = await api.put(
      `/v2/activation/constraints/channels/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getFees = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(`/v2/activation/fees${presetId ? query : ''}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateFees = async (id, datas) => {
  try {
    const result = await api.put(`/v2/activation/fees/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getConversionRates = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/conversionRates${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateConversionRates = async (id, datas) => {
  try {
    const result = await api.put(`/v2/activation/conversionRates/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getConversionRatesMedias = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/conversionRatesMedias${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateConversionRatesMedias = async (id, datas) => {
  try {
    const result = await api.put(
      `/v2/activation/conversionRatesMedias/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getMeasures = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/measures${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateMeasures = async (id, datas) => {
  try {
    const result = await api.put(`/v2/activation/measures/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getOfflineCPM = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/offlineCpm${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createOfflineCPM = async datas => {
  try {
    const result = await api.post(`/v2/activation/offlineCpm`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateOfflineCPM = async (id, datas) => {
  try {
    const result = await api.put(`/v2/activation/offlineCpm/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getChannels = async () => {
  try {
    const result = await api.get(`/v2/activation/channels`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getMedias = async (presetId, key) => {
  try {
    const query = `?presetId=${presetId}`;
    const querykey = `&key=${key}`;
    const result = await api.get(
      `/v2/activation/channels/media${presetId ? query : ''}${
        key ? querykey : ''
      }`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createMedia = async media => {
  try {
    const result = await api.post(`/v2/activation/channels/media`, media);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateMedia = async (id, datas) => {
  try {
    const result = await api.put(`/v2/activation/channels/media/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getMediaChannelConfigsById = async channelConfigsId => {
  try {
    const query = `?channelConfigsId=${channelConfigsId}`;

    const result = await api.get(`/v2/activation/channels/media/${query}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getOfflineMediaProxies = async () => {
  try {
    const result = await api.get(`/v2/activation/offlineMediaProxies`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getActivationPresets = async () => {
  try {
    const result = await api.get(`/v2/activation/presets`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createPreset = async data => {
  try {
    const result = await api.post(`/v2/activation/presets`, data);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const deletePreset = async (id, data) => {
  try {
    const result = await api.delete(`/v2/activation/presets/${id}`, {
      data
    });
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getForcedChannel = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/forcedChannels${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createForcedChannel = async datas => {
  try {
    const result = await api.post(`/v2/activation/forcedChannels`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateForcedChannel = async (id, datas) => {
  try {
    const result = await api.put(`/v2/activation/forcedChannels/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteForcedChannel = async id => {
  try {
    const result = await api.delete(`/v2/activation/forcedChannels/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getSeasonality = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/seasonality${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createSeasonality = async datas => {
  try {
    const result = await api.post(`/v2/activation/seasonality`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateSeasonality = async (id, datas) => {
  try {
    const result = await api.put(`/v2/activation/seasonality/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteSeasonality = async id => {
  try {
    const result = await api.delete(`/v2/activation/seasonality/${id}`);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getDefaultChannelSpendings = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/defaultChannelSpendings${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createDefaultChannelSpendings = async datas => {
  try {
    const result = await api.post(
      `/v2/activation/defaultChannelSpendings`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateDefaultChannelSpendings = async (id, datas) => {
  try {
    const result = await api.put(
      `/v2/activation/defaultChannelSpendings/${id}`,
      datas
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteDefaultChannelSpendings = async id => {
  try {
    const result = await api.delete(
      `/v2/activation/defaultChannelSpendings/${id}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getInfoContacts = async presetId => {
  try {
    const query = `?presetId=${presetId}`;
    const result = await api.get(
      `/v2/activation/infoContacts${presetId ? query : ''}`
    );
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateInfoContacts = async (id, datas) => {
  try {
    const result = await api.put(`/v2/activation/infoContacts/${id}`, datas);
    return result.data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createInfoContacts = async media => {
  try {
    const { data } = await api.post(`/v2/activation/infoContacts`, media);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getCampaignConfigs = async () => {
  try {
    const { data } = await api.get(`/v2/activation/campaignConfigs`);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getCampaignGroups = async () => {
  try {
    const { data } = await api.get(`/v2/activation/campaignGroup`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getCampaignGroupRich = async id => {
  try {
    const { data } = await api.get(`/v2/activation/campaignGroup/rich/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getCampaignConfigById = async id => {
  try {
    const { data } = await api.get(`/v2/activation/campaignConfigs/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getCampaignConfigLight = async () => {
  try {
    const { data } = await api.get(`/v2/activation/campaignConfigs/light`);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateCampaignConfig = async (campaignId, datas) => {
  try {
    const { data } = await api.put(
      `/v2/activation/campaignConfigs/${campaignId}`,
      datas
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateManyCampaignConfigs = async campaignConfigs => {
  try {
    const { data } = await api.put(
      `/v2/activation/campaignConfigs/updateMany/`,
      campaignConfigs
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateCampaignGroup = async (campaignId, datas) => {
  try {
    const { data } = await api.put(
      `/v2/activation/campaignGroup/${campaignId}`,
      datas
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateCampaignReport = async (campaignReportId, datas) => {
  try {
    const { data } = await api.put(
      `/v2/activation/campaignReportSlide/${campaignReportId}`,
      datas
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteCampaignGroup = async (campaignId, datas) => {
  try {
    const { data } = await api.delete(
      `/v2/activation/campaignGroup/${campaignId}`,
      datas
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const deleteCampaignConfig = async campaignId => {
  try {
    const { data } = await api.delete(
      `/v2/activation/campaignConfigs/${campaignId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const getMediaOrdersByCampaignId = async campaignId => {
  try {
    const { data } = await api.get(
      `/v2/activation/mediaOrder?campaignId=${campaignId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const updateMediaOrdersExternalId = async (id, newExternalId) => {
  try {
    const { data } = await api.put(`/v2/activation/mediaOrder/${id}`, {
      externalId: {
        externalId: newExternalId
      }
    });
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const getLineItemsByCampaignId = async campaignId => {
  try {
    const { data } = await api.get(
      `/v2/activation/lineItems?campaignConfigsId=${campaignId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const getCreativesByCampaignId = async campaignId => {
  try {
    const { data } = await api.get(
      `/v2/activation/creatives?campaignConfigsId=${campaignId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const deleteMediaOrdersByCampaignId = async campaignId => {
  try {
    const { data } = await api.delete(
      `/v2/activation/mediaOrder/campaignConfigs/${campaignId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const deleteLineItemsByCampaignId = async campaignId => {
  try {
    const { data } = await api.delete(
      `/v2/activation/lineItems/campaignConfigs/${campaignId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};
const deleteCreativesByCampaignId = async campaignId => {
  try {
    const { data } = await api.delete(
      `/v2/activation/creatives/campaignConfigs/${campaignId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getActivationShops = async () => {
  try {
    const { data } = await api.get(`/v2/activation/activationShop`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createActivationShop = async datas => {
  try {
    const { data } = await api.post(`/v2/activation/activationShop`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateActivationShop = async (id, datas) => {
  try {
    const { data } = await api.put(
      `/v2/activation/activationShop/${id}`,
      datas
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getActivableMedias = async children => {
  try {
    const { data } = await api.get(
      `/v2/activation/activableMedias/all?children=${
        children ? 'true' : 'false'
      }`
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const updateActivableMedia = async (id, datas) => {
  try {
    const { data } = await api.put(
      `/v2/activation/activableMedias/${id}`,
      datas
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const createActivableMedia = async datas => {
  try {
    const { data } = await api.post(`/v2/activation/activableMedias`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getActivationShopGoups = async () => {
  try {
    const { data } = await api.get('/v2/activation/shopGroup');
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getActivationShopGoupById = async id => {
  try {
    const { data } = await api.get(`/v2/activation/shopGroup/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateActivationShopGoup = async (id, datas) => {
  try {
    const { data } = await api.put(`/v2/activation/shopGroup/${id}`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createActivationShopGoup = async datas => {
  try {
    const { data } = await api.post('/v2/activation/shopGroup', datas);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const deleteActivationShopGoup = async id => {
  try {
    const { data } = await api.delete(`/v2/activation/shopGroup/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getCampaignOptions = async () => {
  try {
    const { data } = await api.get(`/v2/activation/campaignOptions`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const createCampaignOptions = async payload => {
  try {
    const { data } = await api.post(`/v2/activation/campaignOptions`, payload);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateCampaignOptions = async (id, payload) => {
  try {
    const { data } = await api.put(
      `/v2/activation/campaignOptions/${id}`,
      payload
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const postCampaignSetup = async payload => {
  try {
    const { data } = await api.post(`/v2/activation/campaignSetup`, payload);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const abortCampaignSetup = async payload => {
  try {
    const { data } = await api.post(
      `/v2/activation/campaignSetup/abort`,
      payload
    );
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const getCampaignLastSetupTasks = async (ids, level = 'campaignSetupTask') => {
  try {
    const { data } = await api.post(
      `/v2/activation/campaignSetupTasks/ids/last`,
      {
        ids,
        level
      }
    );
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getCampaignSetupTaskByCampaignId = async payload => {
  try {
    const { data } = await api.post(
      `/v2/activation/campaignSetupTasks/ids`,
      payload
    );
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getCampaignSetupTasks = async ids => {
  try {
    const { data } = await api.post(`/v2/activation/campaignSetupTasks/ids`, {
      ids
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getMediaSetupTaskByCampaignSetupTask = async ids => {
  try {
    const { data } = await api.post(`/v2/activation/mediaSetupTask/ids`, {
      ids
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const downloadAssetsByCampaignId = async id => {
  try {
    const { data } = await bufferApi.get(
      `/v2/activation/uploadedAsset/downloadByCampaignId/${id}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const launchCampaignSetup = async id => {
  try {
    const { data } = await api.post(`/v2/activation/campaignSetup`, {
      campaignConfigsId: id
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getCampaignBySearch = async filters => {
  try {
    const { data } = await api.post(
      `/v2/activation/campaignConfigs/search`,
      filters
    );
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getMediaOrderById = async id => {
  try {
    const { data } = await api.get(`/v2/activation/mediaOrder/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const getMediaObjectives = async () => {
  try {
    const { data } = await api.get(`/v2/activation/mediaObjectives`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const updateMediaOrderById = async (id, datas) => {
  try {
    const { data } = await api.put(`/v2/activation/mediaOrder/${id}`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getAvailableCreativeByIds = async ids => {
  try {
    const { data } = await api.post(`/v2/activation/availableCreatives/ids`, {
      ids
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};
const downloadCreatives = async id => {
  try {
    const result = await bufferApi.get(
      `/v2/activation/uploadedAsset/download/${id}`
    );
    return result;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getUploadedAsset = async id => {
  try {
    const { data } = await api.get(`/v2/activation/uploadedAsset/${id}`);
    return data;
  } catch (err) {
    console.error('getUploadedAsset', err);
    return null;
  }
};

const getLineItemById = async id => {
  try {
    const { data } = await api.get(`/v2/activation/lineItems/${id}`);
    return data;
  } catch (err) {
    console.error('getLineItemById', err);
    return null;
  }
};

const updateLineItemById = async (id, datas) => {
  try {
    const { data } = await api.put(`/v2/activation/lineItems/${id}`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const getCreativeById = async id => {
  try {
    const { data } = await api.get(`/v2/activation/creatives/${id}`);
    return data;
  } catch (err) {
    console.error('getLineItemById', err);
    return null;
  }
};

const updateCreativeById = async (id, datas) => {
  try {
    const { data } = await api.put(`/v2/activation/creatives/${id}`, datas);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const setupConnectors = async campaignConfigIds => {
  try {
    const { data } = await api.post(`/v2/activation/setup/connector`, {
      campaignConfigIds
    });
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getAllTags = async () => {
  try {
    const { data } = await api.get(`/v2/activation/campaignConfigs/tags`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const updateManyFees = async fees => {
  try {
    const { data } = await api.put(`/v2/activation/fees/updateMany`, fees);
    return data;
  } catch (e) {
    return [];
  }
};

const getAllCampaignSlide = async () => {
  try {
    const { data } = await api.get(`/v2/activation/campaignReportSlide`);
    return data;
  } catch (e) {
    return null;
  }
};

const getCampaignReportById = async id => {
  try {
    const { data } = await api.get(`/v2/activation/campaignReportSlide/${id}`);
    return data;
  } catch (err) {
    console.error(err);
    return {};
  }
};

const sendEmailCampaignReportSlide = async campaignConfigIds => {
  try {
    const { data } = await api.post(
      `/v2/activation/campaignReportSlide/sendEmails`,
      {
        campaignConfigIds
      }
    );
    return data;
  } catch (err) {
    console.error(err);
    return false;
  }
};

const getCampaignConfigByShopIds = async activationShopIds => {
  try {
    const { data } = await api.post(`/v2/activation/campaignConfigs/shops`, {
      ids: activationShopIds
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  getConstraints,
  updateConstraints,
  createConstraintsContextualBetaRanges,
  getConstraintsContextualBetaRanges,
  updateConstraintsContextualBetaRanges,
  deleteConstraintsContextualBetaRanges,
  getConstraintsChannels,
  updateConstraintsChannels,
  getFees,
  updateFees,
  getConversionRates,
  updateConversionRates,
  getConversionRatesMedias,
  updateConversionRatesMedias,
  getMeasures,
  updateMeasures,
  getOfflineCPM,
  createOfflineCPM,
  updateOfflineCPM,
  getChannels,
  getMedias,
  createMedia,
  updateMedia,
  getMediaChannelConfigsById,
  getOfflineMediaProxies,
  getActivationPresets,
  createPreset,
  deletePreset,
  getForcedChannel,
  createForcedChannel,
  updateForcedChannel,
  deleteForcedChannel,
  getSeasonality,
  createSeasonality,
  updateSeasonality,
  deleteSeasonality,
  getDefaultChannelSpendings,
  createDefaultChannelSpendings,
  updateDefaultChannelSpendings,
  deleteDefaultChannelSpendings,
  updateCampaignGroup,
  deleteCampaignGroup,
  getInfoContacts,
  updateInfoContacts,
  createInfoContacts,
  getCampaignConfigs,
  getCampaignConfigLight,
  getCampaignConfigById,
  updateManyCampaignConfigs,
  updateCampaignConfig,
  updateCampaignReport,
  getActivationShops,
  updateActivationShop,
  createActivationShop,
  getActivableMedias,
  createActivableMedia,
  updateActivableMedia,
  deleteCampaignConfig,
  getMediaOrdersByCampaignId,
  getLineItemsByCampaignId,
  getCreativesByCampaignId,
  deleteMediaOrdersByCampaignId,
  deleteLineItemsByCampaignId,
  deleteCreativesByCampaignId,
  getActivationShopGoups,
  getActivationShopGoupById,
  updateActivationShopGoup,
  createActivationShopGoup,
  deleteActivationShopGoup,
  getCampaignOptions,
  createCampaignOptions,
  updateCampaignOptions,
  postCampaignSetup,
  getCampaignSetupTasks,
  getCampaignLastSetupTasks,
  getCampaignSetupTaskByCampaignId,
  getCampaignBySearch,
  getMediaSetupTaskByCampaignSetupTask,
  downloadAssetsByCampaignId,
  launchCampaignSetup,
  updateMediaOrdersExternalId,
  getMediaOrderById,
  getMediaObjectives,
  updateMediaOrderById,
  getAvailableCreativeByIds,
  downloadCreatives,
  getUploadedAsset,
  getLineItemById,
  updateLineItemById,
  getCreativeById,
  updateCreativeById,
  setupConnectors,
  getAllTags,
  getCampaignGroups,
  updateManyFees,
  getCampaignGroupRich,
  getAllCampaignSlide,
  getCampaignReportById,
  sendEmailCampaignReportSlide,
  getCampaignConfigByShopIds,
  abortCampaignSetup
};

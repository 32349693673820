import React, { useMemo } from 'react';
import { Table } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';
import { IconButton } from 'components/Inputs';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { BRANDIDENTITY } from 'utils/rest';

function BrandIdentityList({
  rows,
  setShowTable,
  setSelectedBrandIdentity,
  IOSSwitch,
  page,
  setPage,
  totalItems,
  searchValue,
  setSearchValue,
  setSearch,
  search,
  setSelectedFormats,
  setRows
}) {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const paginatedRows = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return rows.slice(start, end);
  }, [rows, page, rowsPerPage]);

  const headers = [
    { label: "Nom de l'organisation", id: 'organisation' },
    { label: 'Formats de la marque', id: 'marque' },
    { label: 'Templates', id: 'hasTemplate', value: 'off' },
    { id: 'editColumn', label: '' }
  ];

  const rowsWithToggle = useMemo(() => {
    return rows?.map(row => ({
      id: row.id,
      organisation: row.organisation,
      marque: '1080X1080-375X223-etc',
      hasTemplate: (
        <IOSSwitch
          checked={row.hasTemplate}
          onChange={e => handleToggleChange(row.id, !row.hasTemplate)}
          color="primary"
        />
      ),
      editColumn: (
        <IconButton
          onClick={() => handleEdit(row.id)}
          disabled={!row.hasTemplate}
        >
          <EditIcon />
        </IconButton>
      )
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, paginatedRows]);

  const loadUserBrandIdentity = async id => {
    const result = await BRANDIDENTITY.getUserBrandIdentity(id);

    if (result) {
      setSelectedFormats(result?.predefined_templates);
    }
  };

  const handleEdit = id => {
    const foundRow = rows.find(elm => elm.id === id);
    if (foundRow) {
      loadUserBrandIdentity(id);
      setSelectedBrandIdentity(foundRow);
      setShowTable(false);
    }
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = event => {
    setSearchValue(event.target.value);
  };
  const handleClearSearch = () => {
    setSearchValue('');
    if (page === 1) setSearch(!search);
    else setPage(1);
  };
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (page === 1) setSearch(!search);
      else setPage(1);
    }
  };
  const handleToggleChange = (id, value) => {
    const foundRow = rows.find(elm => elm.id === id);
    if (foundRow) {
      setSelectedBrandIdentity(foundRow);
    }

    setRows(prevRows =>
      prevRows.map(row =>
        row.id === id ? { ...row, hasTemplate: value } : row
      )
    );
  };
  return (
    <Container>
      <Row>
        <Item justify="flex-end">
          <TextField
            variant="outlined"
            placeholder="Rechercher..."
            value={searchValue}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchValue && (
                <InputAdornment position="end">
                  <IconButton onClick={handleClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            style={{ maxWidth: 400 }}
          />
        </Item>
        <Item>
          <Table
            rows={rowsWithToggle}
            headers={headers}
            page={page}
            rowsPerPage={rowsPerPage}
            count={totalItems}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Item>
      </Row>
    </Container>
  );
}

export default BrandIdentityList;

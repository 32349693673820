import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { FormHelperText } from '@mui/material';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';

import { capitalize } from 'utils';
import { defaultModule } from 'utils/constants/domainModules';
import {
  serializePlatformAccount,
  serializePlatformPage
} from 'utils/functions';
import { DOMAINCONFIG } from 'utils/rest';

import { HelpTooltip, Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import Image from 'components/Image';
import {
  AutocompleteTextField,
  Button,
  ColorPicker,
  IconButton,
  MultipleSelect,
  Select,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

import { flattenDataProviders } from '../../utils';

const formatModule = () => {
  const formated = {};
  Object.keys(defaultModule).forEach(key => {
    formated[key] = defaultModule[key].state;
  });
  return formated;
};

const campaignStartDayRanges = Array.from({ length: 19 }, (_, i) => {
  const value = i + 2;
  return { value, label: value.toString() };
});

const delayDurationRanges = [
  { value: 'day', label: 'Jour' },
  { value: 'week', label: 'Sem' },
  { value: 'month', label: 'Mois' }
];

export const ModalForm = props => {
  const {
    config,
    dataProviders: dataProvidersOptions,
    preset,
    onValidate,
    onClose,
    actionName,
    connectorsConnexions,
    importModels,
    activableMedias,
    geoInterfaces
  } = props;
  const { themeColors } = useTheme();

  const formatColors = () => {
    const formated = {
      primary_header: themeColors.primary_header,
      txtHeaderColor: themeColors.themeV2.white
    };
    Object.keys(defaultModule).forEach(key => {
      const { colorKey } = defaultModule[key];
      formated[colorKey] = themeColors[colorKey];
    });
    return formated;
  };

  const { domainConfig } = useApp();

  const hiddenFileLogoInput = useRef(null);
  const hiddenFileDarkLogoInput = useRef(null);
  const initialState = {
    name: config?.name || '',
    spendings: !!config?.features?.spendings,
    digitaleo: !!config?.features?.digitaleo,
    enabledPmlFront: !!config?.features?.enabledPmlFront,
    activationEnrichedHome: !!config?.features?.activationEnrichedHome,
    magasinViewOnly: !!config?.features?.magasinViewOnly,
    copyPal: !!config?.features?.copyPal,
    sendCreationEmailOnCampaignValidation: !!config?.features
      ?.sendCreationEmailOnCampaignValidation,
    campaignCrmPreset: config?.campaignCrm?.presetId,
    campaignCrmActivated: config?.campaignCrm?.active,
    campaignStartDayRange: config?.campaignStartDayRange || 2,
    campaignDurationAvertissement:
      config?.campaignDurationAvertissement || false,
    minDelayDuration: config?.minDelayDuration || 0,
    maxDelayDuration: config?.minDelayDuration || 0,
    minDelayUnit: config?.minDelayUnit || 'Jour',
    maxDelayUnit: config?.maxDelayUnit || 'Jour',
    activablePurchaseOrder: config?.activablePurchaseOrder || false,
    sendCreationEmail:
      config?.features?.sendCreationEmail === false
        ? config?.features?.sendCreationEmail
        : true,
    maskFees: !!config?.features?.maskFees,
    dexemActivated: !!config?.dexem,
    dexemEmails: (config?.dexemConfig?.emails || []).map(el => ({ email: el })),
    dexemConnectionId: config?.dexemConfig?.connectionId,
    logo: config?.logo || '',
    domain: config?.domain || '',
    darkLogo: config?.darkLogo || '',
    dataProviders: config.dataProviders.map(el => ({ key: el, label: el })),
    excludeDataProviders: config.excludeDataProviders.map(el => ({
      key: el,
      label: el
    })),
    emailContact: config?.emailContact || '',
    dataFeedbackConfigNoInvoice: !!config?.dataFeedbackConfigNoInvoice,
    cgu: config?.cgu || '',
    dpa: config?.dpa || '',
    legalTerms: config?.legalTerms || '',
    privacyPolicy: config?.privacyPolicy || '',
    modules: {
      ...formatModule(),
      ...(config?.modules || {})
    },
    selectedPlatformPages:
      config?.platformPages?.map(e => {
        const connector = connectorsConnexions.find(
          el => el.value === e.config.connexionId
        );
        return {
          name: e.name,
          media: e.media,
          connexionId: connector
            ? {
                value: e.config.connexionId,
                label: connector?.label
              }
            : undefined,

          externalId: e.config.externalId,
          status: e.status
        };
      }) || [],
    selectedPlatformAccounts:
      config?.platformAccounts?.map(e => {
        const connector = connectorsConnexions.find(
          el => el.value === e.config.connexionId
        );
        const linkConnector = connectorsConnexions.find(
          el => el.value === e.config.linkConnexionId
        );
        return {
          name: e.name,
          model: e.model,
          connexionId: connector
            ? {
                value: e.config.connexionId,
                label: connector?.label
              }
            : undefined,
          externalId: e.config.externalId,
          linkConnexionId: linkConnector
            ? {
                value: e.config.linkConnexionId,
                label: linkConnector?.label
              }
            : undefined,
          linkExternalId: e.config.linkExternalId,
          status: e.status
        };
      }) || [],
    shopName: config.groupTaxonomy?.find(el => el.level === 'shop') || {
      level: 'shop',
      name: 'Magasin',
      key: 'shop'
    },
    groupTaxonomy: config.groupTaxonomy || [],
    autoLoginEmail: '',
    createCustomers: config?.createCustomers,
    customTheme: config?.customTheme || formatColors(),
    selectedActivableMedias:
      config?.activableMedias || domainConfig?.activableMedias || [],
    selectedGeoInterfaces:
      !config?.geoInterfaces || config?.geoInterfaces?.length === 0
        ? geoInterfaces.map(el => el.type)
        : config?.geoInterfaces,
    isLightContent: !!domainConfig?.isLightContent,
    shortCampaign: !!config?.features?.shortCampaign,
    longCampaign: !!config?.features?.longCampaign,
    customBrevoV2ApiKey: config?.features?.customBrevoV2ApiKey || '',
    customBrevoV3ApiKey: config?.features?.customBrevoV3ApiKey || '',
    budgetSmoothing: !!config?.features?.budgetSmoothing,
    autoSendInvoice: !!config?.features?.autoSendInvoice
  };

  const errorState = {
    nameError: false,
    cguError: false,
    dpaError: false,
    legalTermsError: false,
    privacyPolicyError: false,
    shopNameError: false,
    campaignCrmPresetError: false,
    dexemConnectionIdError: false
  };

  const [autoLoginEmailError, setAutoLoginEmailError] = useState('');
  const [token, setToken] = useState('');
  const [
    {
      nameError,
      cguError,
      dpaError,
      legalTermsError,
      privacyPolicyError,
      shopNameError,
      campaignCrmPresetError
    },
    setError
  ] = useState(errorState);

  const [
    {
      name,
      spendings,
      enabledPmlFront,
      digitaleo,
      activationEnrichedHome,
      magasinViewOnly,
      sendCreationEmailOnCampaignValidation,
      campaignCrmPreset,
      campaignCrmActivated,
      campaignStartDayRange,
      logo,
      darkLogo,
      domain,
      dataProviders,
      excludeDataProviders,
      modules,
      emailContact,
      cgu,
      dpa,
      legalTerms,
      privacyPolicy,
      selectedPlatformAccounts,
      selectedPlatformPages,
      dataFeedbackConfigNoInvoice,
      campaignDurationAvertissement,
      minDelayDuration,
      maxDelayDuration,
      minDelayUnit,
      maxDelayUnit,
      shopName,
      groupTaxonomy,
      sendCreationEmail,
      autoLogin,
      autoLoginEmail,
      createCustomers,
      customTheme,
      activablePurchaseOrder,
      copyPal,
      selectedActivableMedias,
      selectedGeoInterfaces,
      isLightContent,
      shortCampaign,
      longCampaign,
      customBrevoV2ApiKey,
      customBrevoV3ApiKey,
      maskFees,
      budgetSmoothing,
      dexemEmails,
      dexemActivated,
      dexemConnectionId,
      autoSendInvoice
    },
    setState
  ] = useState(initialState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const checkIsAutoLogin = async () => {
    const response = await DOMAINCONFIG.checkIsAutoLogin(config?.name);
    if (Object.keys(response).length !== 0)
      setState(prevState => ({
        ...prevState,
        autoLogin: response.actif
      }));
  };
  useEffect(() => {
    checkIsAutoLogin();
    // eslint-disable-next-line
  }, []);

  const handleModulesChange = (key, value) => {
    const newModules = { ...modules };
    newModules[key] = value;

    setState(prevState => ({
      ...prevState,
      modules: newModules
    }));
  };

  const getFileBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // Since FileReader is asynchronous,
    // we need to pass data back.
    reader.onload = () => callback(reader.result);
  };

  const imageUploadCallback = file =>
    new Promise(resolve =>
      getFileBase64(file, data => resolve({ data: { link: data } }))
    );

  const handleChange = key => value => {
    if (['cgu', 'dpa', 'legalTerms', 'privacyPolicy'].includes(key)) {
      toggleError(key, false);
    }
    if (key === 'autoLoginEmail') {
      setAutoLoginEmailError('');
    }
    setState(prevState => {
      const states = { ...prevState };
      if (key === 'shopName') {
        states[key] = {
          level: 'shop',
          name: value,
          key: 'shop'
        };
      } else {
        states[key] = value;
      }
      return states;
    });
  };

  const handleChangeColor = key => value => {
    setState(prevState => {
      const states = { ...prevState };
      if (!states.customTheme) {
        states.customTheme = {};
      }
      states.customTheme[key] = value;
      return states;
    });
  };

  const handleChangeGroupTaxonomyAdd = value => {
    const updatedValue = value.map(item => {
      const itemName = item.name || '';
      const key = itemName
        .replace(/[^\w\s]/gi, '')
        .toLowerCase()
        .replace(/\s/g, '');
      return { ...item, key };
    });

    setState(prevState => {
      const states = { ...prevState };
      states.groupTaxonomy = updatedValue;
      return states;
    });
  };

  const handleLogoChange = key => async event => {
    const fileUploaded = event.target.files[0];
    const { data } = await imageUploadCallback(fileUploaded);
    handleChange(key)(data?.link);
  };

  const handleChangePlatformAccount = value => {
    setState(prevState => ({
      ...prevState,
      selectedPlatformAccounts: value.map(el => {
        let { connexionId } = el;
        if (typeof el.connexionId === 'string') {
          connexionId = {
            value: el.connexionId,
            label: connectorsConnexions.find(e => e.value === el.connexionId)
              ?.label
          };
        }
        return {
          ...el,
          connexionId
        };
      })
    }));
  };

  const handleChangePlatformPage = value => {
    setState(prevState => ({
      ...prevState,
      selectedPlatformPages: value.map(el => {
        let { connexionId } = el;
        if (typeof el.connexionId === 'string') {
          connexionId = {
            value: el.connexionId,
            label: connectorsConnexions.find(e => e.value === el.connexionId)
              ?.label
          };
        }
        return {
          ...el,
          connexionId
        };
      })
    }));
  };

  const matchRegex = url => {
    return !/^https:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s]*)?$/.test(url);
  };

  const doAction = () => {
    const isEmptyDataProviders = dataProviders.length === 0;
    const isEmptyExcludeDataProviders = excludeDataProviders.length === 0;
    let error = false;
    if (cgu && matchRegex(cgu)) {
      toggleError('cgu', true);
      error = true;
    }
    if (dpa && matchRegex(dpa)) {
      toggleError('dpa', true);
      error = true;
    }
    if (legalTerms && matchRegex(legalTerms)) {
      toggleError('legalTerms', true);
      error = true;
    }
    if (privacyPolicy && matchRegex(privacyPolicy)) {
      toggleError('privacyPolicy', true);
      error = true;
    }
    if (campaignCrmActivated && !campaignCrmPreset) {
      toggleError('campaignCrmPreset', true);
      error = true;
    }
    if (dexemActivated && !dexemConnectionId) {
      toggleError('dexemConnectionId', true);
      error = true;
    }
    const emails =
      dexemEmails?.map(el => {
        return el.email;
      }) || [];
    const datas = {
      name,
      autoLogin,
      campaignCrm: {
        active: campaignCrmActivated,
        presetId: campaignCrmPreset
      },
      dexem: dexemActivated,
      dexemConfig: {
        connectionId: dexemConnectionId,
        emails
      },
      campaignStartDayRange,
      campaignDurationAvertissement,
      minDelayDuration,
      maxDelayDuration,
      minDelayUnit,
      maxDelayUnit,
      features: {
        spendings,
        enabledPmlFront,
        digitaleo,
        activationEnrichedHome,
        magasinViewOnly,
        sendCreationEmailOnCampaignValidation,
        sendCreationEmail,
        copyPal,
        shortCampaign,
        longCampaign,
        customBrevoV2ApiKey,
        customBrevoV3ApiKey,
        maskFees,
        budgetSmoothing,
        autoSendInvoice
      },
      logo,
      darkLogo,
      emailContact,
      cgu,
      dpa,
      legalTerms,
      privacyPolicy,
      domain,
      dataProviders: isEmptyDataProviders
        ? dataProviders
        : flattenDataProviders(dataProviders),
      excludeDataProviders: isEmptyExcludeDataProviders
        ? excludeDataProviders
        : flattenDataProviders(excludeDataProviders),
      modules,
      dataFeedbackConfigNoInvoice,
      platformAccounts: selectedPlatformAccounts.map(serializePlatformAccount),
      platformPages: selectedPlatformPages.map(el => serializePlatformPage(el)),
      groupTaxonomy: [
        shopName,
        ...groupTaxonomy.filter(el => el.level !== 'shop')
      ],
      createCustomers,
      customTheme,
      activablePurchaseOrder,
      activableMedias: selectedActivableMedias,
      geoInterfaces:
        selectedGeoInterfaces.length === geoInterfaces.length
          ? []
          : selectedGeoInterfaces,
      isLightContent
    };

    if (!error) {
      onValidate(datas);
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.backoffice.global}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName} le domaine
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  const generateToken = async () => {
    if (!autoLoginEmail.trim()) {
      setAutoLoginEmailError('Ce champ est obligatoire');
      return;
    }
    const response = await DOMAINCONFIG.generateAutoLoginToken(
      config?.name,
      autoLoginEmail
    );
    if (Object.keys(response).length === 0) {
      setAutoLoginEmailError('Email non existant');
      return;
    }
    setToken(response.jwtToken);
  };

  const getModuleName = key => {
    switch (key) {
      case 'alice':
        return 'Contenus';
      case 'crm':
        return 'CRM';
      default:
        return capitalize(key);
    }
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général" xs={3}>
              <Row spacing={0} justify="space-between">
                <Item xs={5} justify="flex-start">
                  <TextField
                    title="Nom du domain"
                    value={name}
                    error={nameError}
                    placeholder="nom du domain"
                    onChange={handleChange('name')}
                  />
                </Item>
                <Item xs={5} justify="flex-start">
                  <Switch
                    title="Masquer les frais data dans le funnel"
                    checked={dataFeedbackConfigNoInvoice}
                    onChange={handleChange('dataFeedbackConfigNoInvoice')}
                    options={['Afficher', 'Masquer']}
                  />
                </Item>
              </Row>
              <Row spacing={0}>
                <Item justify="flex-start">
                  <Row spacing={0} justify="space-between">
                    <Item xs={5} justify="flex-start">
                      <Select
                        title="Délai pour lancement de campagnes PAL"
                        small
                        value={campaignStartDayRange}
                        options={campaignStartDayRanges}
                        onChange={value => {
                          handleChange('campaignStartDayRange')(value);
                        }}
                      />
                    </Item>
                  </Row>
                </Item>
              </Row>
              <Row spacing={0} alignItems="center">
                <Item justify="flex-start">
                  <Item xs={3} justify="flex-start">
                    <Switch
                      title="Avertissement sur la durée"
                      checked={campaignDurationAvertissement}
                      onChange={handleChange('campaignDurationAvertissement')}
                      options={['', 'Activer']}
                    />
                  </Item>
                  {campaignDurationAvertissement && (
                    <>
                      <Item xs={2} justify="flex-start" sx={{ marginRight: 1 }}>
                        <TextField
                          title="Délai minimum"
                          value={minDelayDuration}
                          placeholder="0"
                          onChange={value => {
                            handleChange('minDelayDuration')(value);
                          }}
                        />
                      </Item>
                      <Item
                        xs={1.2}
                        justify="flex-start"
                        sx={{ marginRight: 4 }}
                      >
                        <Select
                          title="Unité"
                          small
                          value={minDelayUnit}
                          options={delayDurationRanges}
                          onChange={value => {
                            handleChange('minDelayUnit')(value);
                          }}
                        />
                      </Item>
                      <Item xs={2} justify="flex-start" sx={{ marginRight: 1 }}>
                        <TextField
                          title="Délai maximum"
                          value={maxDelayDuration}
                          placeholder="0"
                          onChange={value => {
                            handleChange('maxDelayDuration')(value);
                          }}
                        />
                      </Item>
                      <Item xs={1.2} justify="flex-start">
                        <Select
                          title="Unité"
                          small
                          value={maxDelayUnit}
                          options={delayDurationRanges}
                          onChange={value => {
                            handleChange('maxDelayUnit')(value);
                          }}
                        />
                      </Item>
                    </>
                  )}
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Sources de données" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Container>
                          <Item justify="flex-start">
                            <MultipleSelect
                              title="Inclure"
                              small
                              placeHolder="Toutes les sources de données"
                              options={dataProvidersOptions}
                              selected={dataProviders.map(el => el.key)}
                              onChange={value => {
                                handleChange('dataProviders')(value);
                              }}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Container>
                          <Item justify="flex-start">
                            <MultipleSelect
                              title="Exclure"
                              small
                              placeHolder="Aucune source de données"
                              options={dataProvidersOptions}
                              selected={excludeDataProviders.map(el => el.key)}
                              onChange={value => {
                                handleChange('excludeDataProviders')(value);
                              }}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Hiéarchie des groupements" xs={3}>
              <Row spacing={0} justify="space-between">
                <Item
                  xs={5}
                  justify="flex-start"
                  style={{
                    minWidth: '600px'
                  }}
                >
                  <ListField
                    values={groupTaxonomy.filter(el => el.level !== 'shop')}
                    onChange={handleChangeGroupTaxonomyAdd}
                    actionTxt="Ajouter un niveau"
                    deleteAction
                    variant="customFields"
                    inputs={['TextField', 'TextField']}
                    labels={['Niveau', 'Nom']}
                    options={['level', 'name']}
                  />
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Nom des magasins" xs={3}>
              <Row spacing={0} justify="space-between">
                <Item xs={5} justify="flex-start">
                  <TextField
                    title="Nom des magasins"
                    value={shopName?.name}
                    error={shopNameError}
                    placeholder="Choisissez le nom de vos magasins"
                    onChange={handleChange('shopName')}
                  />
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Fonctionnalités" xs={3}>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Upload des dépenses médias offline</Text>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={spendings}
                    color={themeColors.primary}
                    onChange={handleChange('spendings')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Activer campagne CRM</Text>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={campaignCrmActivated}
                    color={themeColors.primary}
                    onChange={handleChange('campaignCrmActivated')}
                  />
                </Item>
              </Row>
              {campaignCrmActivated && (
                <Row spacing={0} justify="flex-start">
                  <Item xs={5} justify="flex-start" sx={{ marginBottom: 3 }}>
                    <AutocompleteTextField
                      title="Sélectionner un preset CRM"
                      placeholder="Choisissez un preset CRM"
                      options={preset.filter(
                        el => el?.domain === config?.domain
                      )}
                      formatLabel={option => {
                        return `${option.name} (${option._id})`;
                      }}
                      small
                      value={preset.find(el => el._id === campaignCrmPreset)}
                      onChange={handleChange('campaignCrmPreset')}
                      error={campaignCrmPresetError}
                    />
                  </Item>
                </Row>
              )}
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Campagnes PAL dans activation</Text>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={activationEnrichedHome}
                    color={themeColors.primary}
                    onChange={handleChange('activationEnrichedHome')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Envoi d’email à la création utilisateur</Text>
                  <HelpTooltip
                    title="Si vous décochez cette option, alors l’utilisateur ne recevra pas d’email lors de sa création pour setuper son mot de passe sur ce domaine et ne pourra pas le reset. Vous pourrez toujours changer ce choix lors de la création d’utilisateur. "
                    style={{
                      paddingLeft: '10px'
                    }}
                  />
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={sendCreationEmail}
                    color={themeColors.primary}
                    onChange={handleChange('sendCreationEmail')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Création d'utilisateur à la première campagne</Text>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={sendCreationEmailOnCampaignValidation}
                    color={themeColors.primary}
                    onChange={handleChange(
                      'sendCreationEmailOnCampaignValidation'
                    )}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Comptes de paiement pour tous</Text>
                  <HelpTooltip
                    title="Des comptes de paiement seront automatiquement créés pour toute organisation actuelle et future du domaine."
                    style={{
                      paddingLeft: '10px'
                    }}
                  />
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    disabled={createCustomers === undefined}
                    checked={createCustomers}
                    color={themeColors.primary}
                    onChange={handleChange('createCustomers')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Activer l’autologin</Text>
                  <HelpTooltip
                    title="L’autologin demande du développement côté équipe plateforme. Ne pas l’activer si celui-ci n’a pas été fait en amont."
                    style={{
                      paddingLeft: '10px'
                    }}
                  />
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    disabled={autoLogin === undefined}
                    checked={autoLogin}
                    color={themeColors.primary}
                    onChange={handleChange('autoLogin')}
                  />
                </Item>
              </Row>
              {autoLogin && (
                <Row
                  style={{
                    alignItems: 'baseline'
                  }}
                  spacing={0}
                  justify="flex-start"
                >
                  <Item
                    xs={3}
                    style={{
                      marginRight: '10px'
                    }}
                  >
                    <TextField
                      value={autoLoginEmail}
                      placeholder="Email utilisateur"
                      onChange={handleChange('autoLoginEmail')}
                      error={autoLoginEmailError.length > 0}
                      errorText={autoLoginEmailError}
                      helperText={
                        <FormHelperText
                          style={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            lineBreak: 'anywhere'
                          }}
                        >
                          {token && `Token généré: ${token}`}
                        </FormHelperText>
                      }
                    />
                  </Item>
                  <Item xs={3}>
                    <Button
                      variant="contained"
                      color={themeColors.backoffice.global}
                      size="medium"
                      widthSize="medium"
                      onClick={generateToken}
                    >
                      Générer un token
                    </Button>
                  </Item>
                </Row>
              )}
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Clients - bons de commande</Text>
                  <HelpTooltip
                    title="Les comptes de paiement créés et existants suivront par défaut le fonctionnement de facturation par bon de commande. Vous pourrez modifier cette valeur indépendamment pour chaque client."
                    style={{
                      paddingLeft: '10px'
                    }}
                  />
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    disabled={activablePurchaseOrder === undefined}
                    checked={activablePurchaseOrder}
                    color={themeColors.primary}
                    onChange={handleChange('activablePurchaseOrder')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Magasin en lecture seule</Text>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={magasinViewOnly}
                    color={themeColors.primary}
                    onChange={handleChange('magasinViewOnly')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Setup PML rôle standard</Text>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={enabledPmlFront}
                    color={themeColors.primary}
                    onChange={handleChange('enabledPmlFront')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Dupliquer les campagnes PAL au sein du réseau</Text>
                  <HelpTooltip
                    title="Si activé, toutes les campagnes PAL d’un réseau seront présentes dans tous les shops de ce réseau"
                    style={{
                      paddingLeft: '10px'
                    }}
                  />
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={copyPal}
                    color={themeColors.primary}
                    onChange={handleChange('copyPal')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Connexion à Digitaléo</Text>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={digitaleo}
                    color={themeColors.primary}
                    onChange={handleChange('digitaleo')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Lissage de budget</Text>
                  <HelpTooltip
                    title="Lorsque cette fonctionnalité est activée, les frais techniques et frais datas seront masqués. Ces montants seront répartis sur les budgets médias."
                    style={{
                      paddingLeft: '10px'
                    }}
                  />
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={maskFees}
                    color={themeColors.primary}
                    onChange={handleChange('maskFees')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>KPI funnel : Budget lissé par jour</Text>
                  <HelpTooltip
                    title="Si actif, on a un KPI funnel Budget à dépenser et Budget dépensé qui est (budget à dépenser / nombre de jours de la campagne) * nombre de jours passés"
                    style={{
                      paddingLeft: '10px'
                    }}
                  />
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={budgetSmoothing}
                    color={themeColors.primary}
                    onChange={handleChange('budgetSmoothing')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Numéros de tracking Dexem</Text>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={dexemActivated}
                    color={themeColors.primary}
                    onChange={handleChange('dexemActivated')}
                  />
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Text>Envoi automatique de facture</Text>
                  <HelpTooltip
                    title="Changer la valeur à ce niveau, modifiera le statut de tous les comptes clients existants. Vous pouvez toujours changer cette valeur pour chaque client dans la fiche client du back-office."
                    style={{
                      paddingLeft: '10px'
                    }}
                  />
                </Item>
                <Item xs={6} justify="flex-start">
                  <Switch
                    checked={autoSendInvoice}
                    color={themeColors.primary}
                    onChange={handleChange('autoSendInvoice')}
                  />
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Personnalisation" xs={3}>
              <Row spacing={0} justify="flex-start">
                <Item xs={8} justify="flex-start">
                  <Row spacing={0} justify="flex-start">
                    <Item xs={4} justify="flex-start" />
                    <Item xs={2}>
                      <Text fontWeight={700}>Actif</Text>
                    </Item>
                    <Item xs={4} justify="center">
                      <Text fontWeight={700} justify="left">
                        Couleur
                      </Text>
                    </Item>
                  </Row>
                  {Object.keys(defaultModule).map(key => {
                    const { colorKey } = defaultModule[key];
                    return (
                      <>
                        <Row spacing={0} justify="flex-start">
                          <Item xs={4} justify="flex-start">
                            <Text>{getModuleName(key)}</Text>
                          </Item>
                          <Item xs={2}>
                            <Switch
                              checked={modules[key]}
                              color={themeColors.primary}
                              onChange={checked => {
                                handleModulesChange(key, checked);
                                if (key === 'alice' && checked) {
                                  handleChange('isLightContent')(!checked);
                                }
                              }}
                            />
                          </Item>
                          <Item xs={4} justify="center">
                            <ColorPicker
                              value={customTheme[colorKey]}
                              onChange={handleChangeColor(colorKey)}
                              iconView
                            />
                          </Item>
                        </Row>
                        {key === 'alice' && !modules[key] && (
                          <Row spacing={0} justify="flex-start">
                            <Item xs={4} justify="flex-start">
                              <Text>Contenus (Editeur only)</Text>
                            </Item>
                            <Item xs={2}>
                              <Switch
                                checked={isLightContent}
                                color={themeColors.primary}
                                onChange={checked => {
                                  handleChange('isLightContent')(checked);
                                }}
                              />
                            </Item>
                          </Row>
                        )}
                      </>
                    );
                  })}
                  <Row
                    spacing={0}
                    justify="flex-start"
                    style={{ height: '37px' }}
                  >
                    <Item xs={4} justify="flex-start">
                      <Text>Header</Text>
                    </Item>
                    <Item xs={2} />
                    <Item xs={4} justify="center">
                      <ColorPicker
                        value={customTheme.primary_header}
                        onChange={handleChangeColor('primary_header')}
                        iconView
                      />
                    </Item>
                  </Row>
                  <Row
                    spacing={0}
                    justify="flex-start"
                    style={{ height: '37px' }}
                  >
                    <Item xs={4} justify="flex-start">
                      <Text>Ecriture</Text>
                    </Item>
                    <Item xs={2} />
                    <Item xs={4} justify="center">
                      <ColorPicker
                        value={customTheme.txtHeaderColor}
                        onChange={handleChangeColor('txtHeaderColor')}
                        iconView
                      />
                    </Item>
                  </Row>
                  <Row
                    spacing={0}
                    justify="flex-start"
                    style={{ height: '37px' }}
                  >
                    <Item xs={4} justify="flex-start" />
                    <Item xs={2} />
                    <Item xs={4} justify="center">
                      <Button
                        color={themeColors.themeV2.black}
                        onClick={() =>
                          handleChange('customTheme')(formatColors())
                        }
                      >
                        <ClearIcon />
                        &nbsp;Reset
                      </Button>
                    </Item>
                  </Row>
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Médias activables" xs={3}>
              <Row spacing={0} justify="flex-start">
                <Item xs={6}>
                  <Item xs={11}>
                    <MultipleSelect
                      small
                      required
                      selectAll
                      options={activableMedias.map(el => {
                        return { label: el.name, key: el.key };
                      })}
                      selected={selectedActivableMedias}
                      onChange={value => {
                        handleChange('selectedActivableMedias')(
                          value.map(el => el.key)
                        );
                      }}
                    />
                  </Item>
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Ciblages géographiques" xs={3}>
              <Row spacing={0} justify="flex-start">
                <Item xs={6}>
                  <Item xs={11}>
                    <MultipleSelect
                      small
                      required
                      selectAll
                      options={geoInterfaces.map(el => {
                        return { label: el.name.fr, key: el.type };
                      })}
                      selected={selectedGeoInterfaces}
                      onChange={value => {
                        handleChange('selectedGeoInterfaces')(
                          value.map(el => el.key)
                        );
                      }}
                    />
                  </Item>
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Logo" xs={3}>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Row spacing={1}>
                    <Item justify="flex-start">
                      <Text>Logo</Text>
                    </Item>
                    <Item justify="flex-start">
                      <Row spacing={0} justify="flex-start" alignItems="center">
                        <Item flex>
                          <input
                            type="file"
                            ref={hiddenFileLogoInput}
                            onChange={handleLogoChange('logo')}
                            style={{
                              display: 'none'
                            }}
                          />
                          {logo ? (
                            <Image
                              src={logo}
                              style={{
                                height: '40px'
                              }}
                            />
                          ) : (
                            <TextField
                              small
                              label="sélectionner une image"
                              disabled
                            />
                          )}
                        </Item>
                        <Item
                          xs
                          justify="flex-start"
                          style={{
                            padding: '0px 40px'
                          }}
                        >
                          {logo && (
                            <IconButton
                              onClick={() => handleChange('logo')('')}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                          <Button
                            variant="contained"
                            size="medium"
                            widthSize="small"
                            onClick={() => {
                              hiddenFileLogoInput.current.click();
                            }}
                          >
                            {logo ? 'Modifier' : 'Parcourir'}
                          </Button>
                        </Item>
                      </Row>
                    </Item>
                  </Row>
                  <Row spacing={1}>
                    <Item justify="flex-start">
                      <Text>Logo sombre</Text>
                    </Item>
                    <Item justify="flex-start">
                      <Row spacing={0} justify="flex-start">
                        <Item flex>
                          <input
                            type="file"
                            ref={hiddenFileDarkLogoInput}
                            onChange={handleLogoChange('darkLogo')}
                            style={{
                              display: 'none'
                            }}
                          />
                          {darkLogo ? (
                            <Image
                              src={darkLogo}
                              style={{
                                height: '40px'
                              }}
                            />
                          ) : (
                            <TextField
                              small
                              label="sélectionner une image"
                              disabled
                            />
                          )}
                        </Item>
                        <Item
                          xs
                          justify="flex-start"
                          style={{
                            padding: '0px 40px'
                          }}
                        >
                          {darkLogo && (
                            <IconButton
                              onClick={() => handleChange('darkLogo')('')}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                          <Button
                            variant="contained"
                            size="medium"
                            widthSize="small"
                            onClick={() =>
                              hiddenFileDarkLogoInput.current.click()
                            }
                          >
                            {darkLogo ? 'Modifier' : 'Parcourir'}
                          </Button>
                        </Item>
                      </Row>
                    </Item>
                  </Row>
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Contact" xs={3}>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Row spacing={0} justify="flex-start">
                    <Item xs={11} justify="flex-start">
                      <TextField
                        title="Adresse email de contact"
                        value={emailContact}
                        placeholder="contact@ermes.ai"
                        onChange={handleChange('emailContact')}
                      />
                    </Item>
                  </Row>
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Confidentialité" xs={3}>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Row spacing={0} justify="flex-start">
                    <Item xs={11} justify="flex-start">
                      <TextField
                        title="Lien de CGU"
                        value={cgu}
                        error={cguError}
                        errorText="Lien incorrect"
                        placeholder="https://..."
                        onChange={handleChange('cgu')}
                      />
                    </Item>
                  </Row>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Row spacing={0} justify="flex-start">
                    <Item xs={11} justify="flex-start">
                      <TextField
                        title="Lien de DPA"
                        value={dpa}
                        error={dpaError}
                        errorText="Lien incorrect"
                        placeholder="https://..."
                        onChange={handleChange('dpa')}
                      />
                    </Item>
                  </Row>
                </Item>
              </Row>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Row spacing={0} justify="flex-start">
                    <Item xs={11} justify="flex-start">
                      <TextField
                        title="Lien des mentions légales"
                        value={legalTerms}
                        error={legalTermsError}
                        errorText="Lien incorrect"
                        placeholder="https://..."
                        onChange={handleChange('legalTerms')}
                      />
                    </Item>
                  </Row>
                </Item>
                <Item xs={6} justify="flex-start">
                  <Row spacing={0} justify="flex-start">
                    <Item xs={11} justify="flex-start">
                      <TextField
                        title="Lien de la politique de confidentialité"
                        value={privacyPolicy}
                        error={privacyPolicyError}
                        errorText="Lien incorrect"
                        placeholder="https://..."
                        onChange={handleChange('privacyPolicy')}
                      />
                    </Item>
                  </Row>
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Mes comptes publicitaires" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      values={selectedPlatformAccounts}
                      onChange={handleChangePlatformAccount}
                      actionTxt="Ajouter un compte"
                      deleteAction
                      variant="customFields"
                      isRequireds={[true, true, true, true, true, false, false]}
                      inputs={[
                        'TextField',
                        'Select',
                        'Select',
                        'TextField',
                        'Select',
                        'Select',
                        'TextField'
                      ]}
                      labels={[
                        'Nom',
                        'Nom de la plateforme',
                        'Connexion',
                        'External ID',
                        'Statut',
                        'Connexion Lié (facultatif)',
                        'External ID Lié (facultatif)'
                      ]}
                      options={[
                        'name',
                        'model',
                        'connexionId',
                        'externalId',
                        'status',
                        'linkConnexionId',
                        'linkExternalId'
                      ]}
                      fieldsFormat={[
                        '',
                        importModels,
                        connectorsConnexions,
                        '',
                        [
                          { label: 'Validé', value: 'validated' },
                          {
                            label: 'En cours de validation',
                            value: 'notValidated'
                          }
                        ],
                        connectorsConnexions,
                        ''
                      ]}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Mes pages" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item justify="flex-start">
                    <ListField
                      values={selectedPlatformPages}
                      onChange={handleChangePlatformPage}
                      actionTxt="Ajouter une page"
                      deleteAction
                      variant="customFields"
                      inputs={[
                        'TextField',
                        'Select',
                        'Select',
                        'TextField',
                        'Select'
                      ]}
                      labels={[
                        'Nom',
                        'Media',
                        'Connexion',
                        'External ID',
                        'Statut'
                      ]}
                      options={[
                        'name',
                        'media',
                        'connexionId',
                        'externalId',
                        'status'
                      ]}
                      fieldsFormat={[
                        '',
                        'pageActivableMedia',
                        connectorsConnexions,
                        '',
                        [
                          {
                            label: 'Validé',
                            value: 'validated'
                          },
                          {
                            label: 'En cours de validation',
                            value: 'notValidated'
                          }
                        ]
                      ]}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        {dexemActivated && (
          <>
            <Row spacing={1}>
              <Item justify="flex-start">
                <FormSection title="Connexion Dexem" xs={3}>
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item justify="flex-start" xs={6}>
                        <Select
                          value={dexemConnectionId}
                          required
                          onChange={handleChange('dexemConnectionId')}
                          title="Sélectionner une connexion Dexem"
                          options={connectorsConnexions.filter(
                            el => el.model === 'dexem'
                          )}
                          small
                        />
                      </Item>
                    </Row>
                  </Container>
                </FormSection>
              </Item>
            </Row>
            <Row spacing={1}>
              <Item justify="flex-start">
                <FormSection title="Mails de contact Dexem" xs={3}>
                  <Container>
                    <Row spacing={0} justify="flex-start">
                      <Item justify="flex-start">
                        <ListField
                          values={dexemEmails}
                          onChange={handleChange('dexemEmails')}
                          actionTxt="Ajouter un mail"
                          deleteAction
                          variant="customFields"
                          inputs={['TextField']}
                          labels={['Email']}
                          options={['email']}
                          fieldsFormat={['']}
                        />
                      </Item>
                    </Row>
                  </Container>
                </FormSection>
              </Item>
            </Row>
          </>
        )}
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Connexion au compte Brevo :" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={3} justify="flex-start">
                    <Text>Campagne courte</Text>
                    <HelpTooltip
                      title="Ce toggle active l’envoi d’email 1j après la fin de la campagne sur les campagnes de moins de 30j"
                      style={{
                        paddingLeft: '10px'
                      }}
                    />
                  </Item>
                  <Item xs justify="flex-start">
                    <Switch
                      checked={shortCampaign}
                      color={themeColors.primary}
                      onChange={handleChange('shortCampaign')}
                    />
                  </Item>
                </Row>
                <Row spacing={0} justify="flex-start">
                  <Item xs={3} justify="flex-start">
                    <Text>Campagne longue</Text>
                    <HelpTooltip
                      title="Ce toggle active l’envoi d’email 30j après le début de la campagne sur les campagnes de plus de 30j"
                      style={{
                        paddingLeft: '10px'
                      }}
                    />
                  </Item>
                  <Item xs justify="flex-start">
                    <Switch
                      checked={longCampaign}
                      color={themeColors.primary}
                      onChange={handleChange('longCampaign')}
                    />
                  </Item>
                </Row>
                <Row spacing={0} justify="space-between">
                  <Item xs={5}>
                    <TextField
                      title="ApiKey Brevo V2"
                      tooltip="Cette API key permet de se connecter au compte Brevo pour générer les automatisations d’envoi de mail"
                      value={customBrevoV2ApiKey}
                      onChange={handleChange('customBrevoV2ApiKey')}
                    />
                  </Item>
                  <Item xs={5}>
                    <TextField
                      title="ApiKey Brevo V3"
                      tooltip="Cette API key permet de se connecter au compte Brevo pour gérer les transactions Brevo"
                      value={customBrevoV3ApiKey}
                      onChange={handleChange('customBrevoV3ApiKey')}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  onClose: () => null,
  actionName: ''
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  config: PropTypes.objectOf(PropTypes.any).isRequired,
  actionName: PropTypes.string,
  activableMedias: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default ModalForm;

import React from 'react';
import PropTypes from 'prop-types';
import SaveIcon from '@mui/icons-material/Save';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { ListField } from 'components/ListField';

const ErrorState = {
  measureError: false,
  budgetsError: false
};

export const ModalForm = props => {
  const { onValidate, config, canEdit, onClose } = props;
  const { selectedDefaultBudget = {}, measures = [], channels = [] } = config;
  const initialState = {
    measure: selectedDefaultBudget.measure || null,
    budgets:
      selectedDefaultBudget.budgets?.map(({ channel, value }) => ({
        channel,
        value: value * 100
      })) || []
  };
  const [{ measure, budgets }, setState] = React.useState(initialState);
  const [{ measureError, budgetsError }, setError] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = (type, itemKey) => value => {
    const text = type === 'text' ? value.trim() : value;
    if (itemKey === 'budgets') {
      const sum = value.reduce((acc, item) => acc + parseFloat(item.value), 0);
      toggleError(itemKey, sum !== 100);
    } else {
      toggleError(itemKey, !text);
    }

    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!measure) {
      toggleError('measure', true);
      error = true;
    }

    const sum = budgets.reduce((acc, item) => acc + parseFloat(item.value), 0);
    if (sum !== 100) {
      toggleError('budgets', true);
      error = true;
    }

    if (!error) {
      const datas = {
        measure,
        budgets: budgets.map(({ channel, value }) => ({
          channel,
          value: value / 100
        }))
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            {budgetsError && (
              <Text color="error">
                Le total du budget n’est pas égal à 100%
              </Text>
            )}
          </Item>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={canEdit ? doAction : onClose}
              disabled={budgetsError}
            >
              {canEdit ? (
                <>
                  <SaveIcon />
                  &nbsp;Sauvegarder
                </>
              ) : (
                'Fermer'
              )}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Type de funnel" xs={3}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Type de funnel"
                            required
                            onChange={handleChange('text', 'measure')}
                            options={measures.map(el => ({
                              value: el._id,
                              label: el.target
                            }))}
                            value={measure}
                            error={measureError}
                            disabled={!canEdit || selectedDefaultBudget._id}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start" />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Budgets" xs={2}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item>
                    <ListField
                      options={['channel', 'value']}
                      labels={['Canal', 'Part du budget']}
                      units={[null, '%']}
                      fieldsFormat={[
                        channels
                          .filter(
                            el => !budgets.some(item => item.channel === el._id)
                          )
                          .map(el => ({
                            value: el._id,
                            label: el.label
                          })),
                        'numberDec'
                      ]}
                      inputs={['Select', 'TextField']}
                      values={budgets}
                      // LISTFIELD_DEBT
                      // formatedValues={budgets.map(({ channel, value }) => ({
                      //   channel: channels.find(item => item._id === channel)
                      //     .label,
                      //   value: `${value}%`
                      // }))}
                      sortValues={list => list.sort((a, b) => a.min - b.min)}
                      onChange={handleChange('object', 'budgets')}
                      actionTxt="Ajouter"
                      deleteAction
                      variant="customFields"
                      disabled={!canEdit}
                    />
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {}
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;

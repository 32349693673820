import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  channelError: false,
  percentError: false
};

export const ModalForm = props => {
  const { onValidate, config, actionBeta, canEdit, onClose } = props;
  const initialState = {
    channel: config?.channel?.label || null,
    percent: config?.percent * 100 || null,
    channels: config?.channel ? [config?.channel] : config.channels
  };
  const [{ channel, percent, channels }, setState] = React.useState(
    initialState
  );
  const [{ channelError, percentError }, setError] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };

  const handleChange = (field, itemKey) => value => {
    const text = value.trim();
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!channel) {
      toggleError('channel', true);
      error = true;
    }
    if (!percent) {
      toggleError('percent', true);
      error = true;
    }

    if (!error) {
      const datas = {
        percent: percent / 100,
        channel: channels.find(el => el.label === channel).key
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={canEdit ? doAction : onClose}
            >
              {canEdit ? actionBeta || 'Valider' : 'Fermer'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Canal"
                            required
                            onChange={handleChange('text', 'channel')}
                            options={channels.map(el => el.label)}
                            value={channel}
                            error={channelError}
                            small
                            disabled={!canEdit || !!config?.channel}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Part du budget"
                            required
                            small
                            value={percent}
                            onChange={handleChange('text', 'percent')}
                            error={percentError}
                            format="numberDec"
                            unit="%"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;

import React, { useEffect, useState } from 'react';

import { USERPROFILE, VIEWS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { HelpTooltip, Text } from 'components/DataDisplay';
import { Select } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';

import { ModalForm } from './components/ModalForm';

const ProfileContainer = () => {
  const [loaded, setLoaded] = useState(false);
  const [views, setViews] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [rows, setRows] = useState([]);
  const [profile, setProfile] = useState();
  const [modal, setModal] = useState('');
  const [selectedView, setSelectedView] = useState();

  const fetchData = async () => {
    const fetchedViews = await VIEWS.get();
    setViews(fetchedViews.map(el => ({ label: el.label, value: el.key })));
    if (fetchedViews.length > 0) {
      setSelectedView(fetchedViews[0].key);
    }
    const fetchedProfiles = await USERPROFILE.get();
    setProfiles(fetchedProfiles);
    setRows(fetchedProfiles);
    setLoaded(true);
  };

  const handleCreate = async datas => {
    await USERPROFILE.create(datas);
    setModal('');
    setLoaded(false);
  };

  const handleUpdate = async datas => {
    await USERPROFILE.update(profile._id, datas);
    setModal('');
    setLoaded(false);
  };

  useEffect(() => {
    if (!loaded) {
      fetchData();
    }
  }, [loaded]);

  const filterRows = newRows => {
    setRows(newRows);
  };

  if (!loaded) {
    return (
      <Container>
        <Loading loading />
      </Container>
    );
  }

  return (
    <Container>
      {modal === 'updateModal' && (
        <ModalForm
          open={modal === 'updateModal'}
          actionName="Mettre à jour"
          onValidate={handleUpdate}
          config={profile}
          views={views}
          onClose={() => {
            setModal('');
          }}
        />
      )}
      {modal === 'createModal' && (
        <ModalForm
          open={modal === 'createModal'}
          views={views}
          actionName="Créer"
          onValidate={handleCreate}
          onClose={() => {
            setModal('');
          }}
        />
      )}
      <Row justify="start">
        <Item xs={3}>
          <SearchField
            onChange={filterRows}
            datas={profiles}
            titleHead="Recherche un profil"
            placeholder="Nom, Key ..."
          />
        </Item>
      </Row>
      <Row>
        <Item xs={3}>
          <Select
            title="Vue"
            onChange={newValue => {
              setSelectedView(newValue);
            }}
            value={selectedView}
            options={views}
          />
        </Item>
        <Item justify="flex-end" xs>
          <ButtonCreate
            onClick={() => {
              setModal('createModal');
            }}
            text="Créer un nouveau profil"
          />
        </Item>
      </Row>
      <Row>
        <Item>
          <TableData
            rows={rows.filter(el => el.viewKey === selectedView)}
            headers={[
              {
                label: 'Nom',
                id: 'name',
                sortKey: 'name',
                format: (_, row) => {
                  return (
                    <Item>
                      <Text>{row.name}&nbsp;</Text>
                      {row.description && (
                        <HelpTooltip title={row.description} />
                      )}
                    </Item>
                  );
                }
              },
              { label: 'Key', id: 'key', sortKey: 'key' }
            ]}
            onUpdate={id => {
              const found = profiles.find(el => el._id === id);
              setProfile(found);
              setModal('updateModal');
            }}
          />
        </Item>
      </Row>
    </Container>
  );
};

export default ProfileContainer;

import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  keyError: false,
  labelError: false,
  maxError: false,
  minError: false,
  offTargetError: false,
  onTargetError: false
};
export const ModalForm = props => {
  const { onValidate, config, actionBeta, canEdit, onClose } = props;
  const initialState = {
    key: config.key || null,
    label: config.label || null,
    label_en: config.label_en || null,
    max: config.max * 100 || null,
    min: config.min * 100 || null,
    offTarget: config.offTarget * 100 || null,
    onTarget: config.onTarget * 100 || null
  };
  const [
    { key, label, label_en, max, min, offTarget, onTarget },
    setState
  ] = React.useState(initialState);
  const [
    { keyError, labelError, maxError, minError, offTargetError, onTargetError },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = (field, itemKey) => value => {
    const text = value.trim();
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!key) {
      toggleError('key', true);
      error = true;
    }
    if (!label) {
      toggleError('label', true);
      error = true;
    }
    if (!max) {
      toggleError('max', true);
      error = true;
    }
    if (!min) {
      toggleError('min', true);
      error = true;
    }
    if (!offTarget) {
      toggleError('offTarget', true);
      error = true;
    }
    if (!onTarget) {
      toggleError('onTarget', true);
      error = true;
    }

    if (!error) {
      const datas = {
        key,
        label,
        label_en: label_en || '',
        max: max / 100,
        min: min / 100,
        offTarget: offTarget / 100,
        onTarget: onTarget / 100
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={canEdit ? doAction : onClose}
            >
              {canEdit ? actionBeta || 'Valider' : 'Fermer'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Key"
                            small
                            value={key}
                            disabled
                            bold
                            error={keyError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Label"
                            required
                            small
                            value={label}
                            onChange={handleChange('text', 'label')}
                            error={labelError}
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} />
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Label_EN"
                            small
                            value={label_en}
                            onChange={handleChange('text', 'label_en')}
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Taux de conversion" xs={3}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Taux de conversion maximum"
                            required
                            small
                            value={max}
                            onChange={handleChange('rate', 'max')}
                            error={maxError}
                            format="numberDec"
                            unit="%"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Taux de conversion minimum"
                            required
                            small
                            value={min}
                            onChange={handleChange('rate', 'min')}
                            error={minError}
                            format="numberDec"
                            unit="%"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Taux de conversion sur cible par défaut"
                            required
                            small
                            value={onTarget}
                            onChange={handleChange('text', 'onTarget')}
                            error={onTargetError}
                            format="numberDec"
                            unit="%"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Taux de conversion hors cible par défaut"
                            required
                            small
                            value={offTarget}
                            onChange={handleChange('text', 'offTarget')}
                            error={offTargetError}
                            format="numberDec"
                            unit="%"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;

import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  channelError: false,
  startError: false,
  durationError: false,
  minBudgetError: false,
  formatError: false,
  maxRepeatError: false,
  averageCPMError: false,
  offTargetISCError: false,
  feeError: false,
  memoBetaError: false
};

export const ModalForm = props => {
  const { onValidate, config, actionBeta, canEdit, onClose } = props;
  const initialState = {
    channel: config.channel,
    start: config.start,
    duration: config.duration,
    minBudget: config.minBudget,
    format: config.format === 'none' ? 'aucun' : config.format,
    maxRepeat: config.maxRepeat,
    averageCPM: config.averageCPM,
    offTargetISC: config.offTargetISC * 100,
    fee: config?.fees.find(el => el._id === config.fee)?.key || null,
    fees: config.fees,
    memoBeta: config.memoBeta
  };
  const [
    {
      channel,
      start,
      duration,
      minBudget,
      format,
      maxRepeat,
      averageCPM,
      offTargetISC,
      fee,
      fees,
      memoBeta
    },
    setState
  ] = React.useState(initialState);
  const [
    {
      channelError,
      startError,
      durationError,
      minBudgetError,
      formatError,
      maxRepeatError,
      averageCPMError,
      offTargetISCError,
      feeError,
      memoBetaError
    },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = (field, itemKey) => value => {
    const text = value.trim();
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!channel) {
      toggleError('channel', true);
      error = true;
    }
    if (!start) {
      toggleError('start', true);
      error = true;
    }
    if (!duration) {
      toggleError('duration', true);
      error = true;
    }
    if (!minBudget) {
      toggleError('minBudget', true);
      error = true;
    }
    if (!format) {
      toggleError('format', true);
      error = true;
    }
    if (!maxRepeat) {
      toggleError('maxRepeat', true);
      error = true;
    }
    if (!averageCPM) {
      toggleError('averageCPM', true);
      error = true;
    }
    if (!offTargetISC) {
      toggleError('offTargetISC', true);
      error = true;
    }
    if (!fee) {
      toggleError('fee', true);
      error = true;
    }
    if (!memoBeta) {
      toggleError('memoBeta', true);
      error = true;
    }

    if (!error) {
      const datas = {
        channel,
        start,
        duration,
        minBudget,
        format: format === 'aucun' ? 'none' : format,
        maxRepeat,
        averageCPM,
        offTargetISC: offTargetISC / 100,
        fee: fees.find(el => el.key === fee).id,
        memoBeta
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={canEdit ? doAction : onClose}
            >
              {canEdit ? actionBeta || 'Valider' : 'Fermer'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Canal"
                            small
                            value={channel}
                            disabled
                            onChange={handleChange('text', 'channel')}
                            error={channelError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Format nécessaire"
                            onChange={handleChange('text', 'format')}
                            options={['aucun', 'image', 'video', 'audio']}
                            value={format}
                            error={formatError}
                            small
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="CPM moyen"
                            small
                            value={averageCPM}
                            onChange={handleChange('text', 'averageCPM')}
                            error={averageCPMError}
                            format="numberDec"
                            unit="€"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Groupe tarifaire"
                            onChange={handleChange('text', 'fee')}
                            options={fees.map(el => el.key)}
                            value={fee}
                            default="aucun"
                            error={feeError}
                            small
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Taux d’impressions sur cible hors data moyen"
                            small
                            value={offTargetISC}
                            onChange={handleChange('rate', 'offTargetISC')}
                            error={offTargetISCError}
                            format="numberDec"
                            unit="%"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Beta de mémorisation"
                            small
                            value={memoBeta}
                            onChange={handleChange('rate', 'memoBeta')}
                            error={memoBetaError}
                            format="numberDec"
                            unit="%"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Contraintes">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Délai"
                            required
                            small
                            value={start}
                            onChange={handleChange('text', 'start')}
                            error={startError}
                            format="numberDec"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Durée minimale"
                            required
                            small
                            value={duration}
                            onChange={handleChange('text', 'duration')}
                            error={durationError}
                            format="numberDec"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Budget minimum"
                            required
                            small
                            value={minBudget}
                            onChange={handleChange('text', 'minBudget')}
                            error={minBudgetError}
                            format="numberDec"
                            unit="€"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Répétition maximale"
                            required
                            small
                            value={maxRepeat}
                            onChange={handleChange('text', 'maxRepeat')}
                            error={maxRepeatError}
                            format="numberDec"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;

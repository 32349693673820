import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import { ACTIVATION } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components';

const MediasContainer = () => {
  const { currentPresetId, canEdit } = useNavigation();
  const [rows, setRows] = useState([]);
  const [medias, setMedias] = useState([]);
  const [channels, setChannels] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState({});
  const { adminRights } = useUser();

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectMedia = id => {
    const media = medias.find(el => el._id === id);
    setSelectedMedia(media);
  };
  const closeModal = () => {
    setModal(null);
    selectMedia({});
  };

  const getMedias = async () => {
    try {
      const result = await ACTIVATION.getMedias(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getChannels = async () => {
    try {
      const result = await ACTIVATION.getChannels();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const loadData = async () => {
    try {
      const newChannels = await getChannels();
      const channelsKey = newChannels.map(c => c.key);
      const newMedias = await getMedias();
      setLoaded(true);
      const mapped = [];
      newMedias.forEach(c =>
        c.channelConfigs.forEach(el => {
          const newItem = {
            _id: el._id,
            label: c.label,
            channel: el.channel,
            media: c.key,
            cpm: el.cpm,
            low: el.visitRates.channelParam.low * 100,
            high: el.visitRates.channelParam.high * 100,
            main: el.visitRates.channelParam.main * 100
          };
          mapped.push(newItem);
        })
      );
      setMedias(mapped);
      setChannels(channelsKey);
      setRows(mapped);
      return;
    } catch (err) {
      console.error(err);
    }
  };

  const openCreateModal = () => {
    if (adminRights?.activation?.update) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectMedia(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async datas => {
    if (!datas.channel || !datas.media) {
      return;
    }

    const result = await ACTIVATION.getMedias(currentPresetId, datas.media);

    const newObj = {
      visitRates: {
        channelParam: {
          low: parseFloat(datas.low) / 100,
          high: parseFloat(datas.high) / 100,
          main: parseFloat(datas.main) / 100
        }
      },
      channel: datas.channel,
      cpm: parseFloat(datas.cpm)
    };

    if (result.length === 0) {
      const newMedia = {
        key: datas.media,
        label: datas.label,
        order: 1,
        channelConfigs: [newObj],
        preset: currentPresetId
      };
      await ACTIVATION.createMedia(newMedia);
      setLoaded(false);
      closeModal();
      return;
    }

    const channelExist = result[0].channelConfigs.find(
      c => c.channel === datas.channel
    );

    if (!channelExist) {
      result[0].channelConfigs.push(newObj);
    } else {
      const newChannelConfigs = result[0].channelConfigs.map(c => {
        if (c.channel === datas.channel) {
          return { ...newObj, _id: c._id };
        }
        return { ...c };
      });
      result[0].channelConfigs = newChannelConfigs;
    }

    await ACTIVATION.updateMedia(result[0]._id, result[0]);
    setLoaded(false);
    closeModal();
  };

  const onUpdate = async datas => {
    const result = await ACTIVATION.getMediaChannelConfigsById(
      selectedMedia._id
    );
    if (!result.length) {
      return;
    }

    // eslint-disable-next-line prefer-template
    const media = result.find(el => el.preset + '' === currentPresetId + '');
    const newChannelConfigs = media.channelConfigs.map(c => {
      if (c._id === selectedMedia._id) {
        return {
          visitRates: {
            channelParam: {
              low: parseFloat(datas.low) / 100,
              high: parseFloat(datas.high) / 100,
              main: parseFloat(datas.main) / 100
            }
          },
          _id: selectedMedia._id,
          channel: datas.channel,
          cpm: parseFloat(datas.cpm)
        };
      }
      return { ...c };
    });
    media.channelConfigs = newChannelConfigs;
    await ACTIVATION.updateMedia(media._id, media);

    setLoaded(false);
    closeModal();
  };

  if (!loaded) {
    loadData();
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item justify="flex-end" xs>
          <ButtonCreate
            onClick={openCreateModal}
            text="Ajouter"
            disabled={!canEdit}
          />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              actionBeta="Ajouter le média"
              channels={channels}
              canEdit={canEdit}
            />
          )}
        </Item>
      </Row>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Channel',
                  id: 'channel'
                },
                {
                  label: 'Médias',
                  id: 'media'
                },
                {
                  label: 'Label',
                  id: 'label'
                },
                {
                  label: 'Taux de visite minimal',
                  id: 'low',
                  format: value => numeral(value).format('0.00')
                },
                {
                  label: 'Taux de visite moyen',
                  id: 'main',
                  format: value => numeral(value).format('0.00')
                },
                {
                  label: 'Taux de visite maximal',
                  id: 'high',
                  format: value => numeral(value).format('0.00')
                },
                {
                  label: 'CPM',
                  id: 'cpm'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={canEdit && openUpdateModal}
              onView={!canEdit && openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ ...selectedMedia }}
              channels={channels}
              canEdit={canEdit}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default MediasContainer;

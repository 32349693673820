import React, { useState, useMemo } from 'react';
import { Container, Item, Row } from 'components/Layout';
import { CircularProgress } from '@material-ui/core';
import { Checkbox } from 'components/Inputs';
import { Text } from 'components/DataDisplay';
import { useTheme } from 'contexts/ThemeContext';
import { Button } from '@mui/material';
import { BRANDIDENTITY } from 'utils/rest';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';

function FormatsList({
  formatsData,
  setShowTable,
  selectedBrandidentity,
  handleToggleChange,
  IOSSwitch,
  selectedFormats,
  setSelectedFormats
}) {
  const [loadingFormats, setLoadingFormats] = useState(false);
  const { themeColors } = useTheme();
  const [open, setOpen] = useState(false);

  const handleSubmit = async () => {
    //save formats
    const body = {
      predefined_templates: selectedFormats,
      hasTemplate: selectedBrandidentity?.hasTemplate
    };
    const result = await BRANDIDENTITY.updateBrandIdentity(
      selectedBrandidentity?.id,
      body
    );
    if (result) {
      setOpen(true);
      setSelectedFormats([]);
      setTimeout(() => {
        setShowTable(true);
      }, 1500);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const groupedPlatforms = useMemo(() => {
    setLoadingFormats(true);
    const displaysAndBannersCategories = [
      'InRead',
      'Masthead',
      'Leaderboard',
      'Skyscrapper',
      'Grand angle',
      'Pavé',
      'Full Screen Interstitiel (mobile)',
      'Carré',
      'Habillage',
      'App/Interstitiel',
      'Autres formats Paysage',
      'Autres formats Portrait'
    ];
    const displaysAndBanners = formatsData?.filter(platform =>
      displaysAndBannersCategories.includes(platform.name)
    );
    const otherPlatforms = formatsData?.filter(
      platform => !displaysAndBannersCategories.includes(platform.name)
    );
    setLoadingFormats(false);

    return [
      ...otherPlatforms,
      {
        name: 'displays_and_banners_iab',
        subcategories: displaysAndBanners
      }
    ];
  }, [formatsData]);

  const handleSelectFormat = (event, format) => {
    if (event.target.checked) {
      setSelectedFormats([...selectedFormats, format]);
    } else {
      setSelectedFormats(prevSelectedFormats =>
        prevSelectedFormats.filter(
          f => f.height !== format.height || f.width !== format.width
        )
      );
    }
  };

  const isFormatInSelected = format => {
    return selectedFormats.some(
      f => f.height === format.height && f.width === format.width
    );
  };

  const handleSelectPlatform = (event, platform) => {
    // Check if the platform is a grouped platform with subcategories
    const isGrouped = platform.name === 'displays_and_banners_iab';
    const formats = isGrouped
      ? platform.subcategories.flatMap(subcategory => subcategory.formats)
      : platform.formats;
    // Determine if the checkbox is in an indeterminate state
    const isIndeterminate =
      isPlatformFormatSelected(platform) &&
      !isAllPlatformFormatSelected(platform);

    if (event.target.checked && !isIndeterminate) {
      const newFormats = formats.filter(format => !isFormatInSelected(format));
      setSelectedFormats(prevSelectedFormats => [
        ...prevSelectedFormats,
        ...newFormats
      ]);
    } else {
      setSelectedFormats(prevSelectedFormats =>
        prevSelectedFormats.filter(
          selectedFormat =>
            !formats.some(
              format =>
                format.height === selectedFormat.height &&
                format.width === selectedFormat.width
            )
        )
      );
    }
  };
  const isAllPlatformFormatSelected = platform => {
    if (!platform.formats) {
      // If the platform has subcategories, check all subcategories
      return platform.subcategories.every(subcategory =>
        subcategory.formats.every(format => isFormatInSelected(format))
      );
    }
    return platform.formats.every(format => isFormatInSelected(format));
  };
  const isPlatformFormatSelected = platform => {
    if (!platform.formats) {
      // If the platform has subcategories, check if any format in subcategories is selected
      return platform.subcategories.some(subcategory =>
        subcategory.formats.some(format => isFormatInSelected(format))
      );
    }
    return platform.formats.some(format => isFormatInSelected(format));
  };

  const isAllSubcategoryFormatsSelected = subcategory => {
    return subcategory.formats.every(format => isFormatInSelected(format));
  };

  const isAnySubcategoryFormatSelected = subcategory => {
    return subcategory.formats.some(format => isFormatInSelected(format));
  };

  const handleSelectSubcategory = (event, subcategory) => {
    const isChecked = event.target.checked;
    // Determine if the checkbox is in an indeterminate state
    const isIndeterminate =
      isAnySubcategoryFormatSelected(subcategory) &&
      !isAllSubcategoryFormatsSelected(subcategory);
    if (isChecked && !isIndeterminate) {
      // Select all formats in the subcategory that are not already selected
      const newFormats = subcategory.formats.filter(
        format => !isFormatInSelected(format)
      );
      setSelectedFormats(prevSelectedFormats => [
        ...prevSelectedFormats,
        ...newFormats
      ]);
    } else {
      // Deselect all formats in the subcategory
      setSelectedFormats(prevSelectedFormats =>
        prevSelectedFormats.filter(
          selectedFormat =>
            !subcategory.formats.some(
              format =>
                format.height === selectedFormat.height &&
                format.width === selectedFormat.width
            )
        )
      );
    }
  };
  return (
    <Container
      alignItems="flex-start"
      justifyContent="flex-start"
      style={{ margin: '20px 0px 0px 30px' }}
    >
      <IconButton onClick={() => setShowTable(true)} color="primary">
        <KeyboardBackspaceIcon />
      </IconButton>
      <Row direction="column" alignItems="flex-start">
        <Item flex alignItems="flex-start">
          {selectedBrandidentity && (
            <Text align="left" fontSize="24px" color="#000000" bold>
              {selectedBrandidentity?.organisation}
            </Text>
          )}
        </Item>

        <Item flex alignItems="flex-start" style={{ marginTop: '20px' }}>
          <Text
            align="left"
            fontSize="20px"
            color="#000000"
            style={{ marginRight: '10px' }}
          >
            Cette marque possède des templates prédéfinis
          </Text>
          <IOSSwitch
            checked={selectedBrandidentity?.hasTemplate}
            onChange={e =>
              handleToggleChange(
                selectedBrandidentity?.id,
                !selectedBrandidentity?.hasTemplate
              )
            }
          />
        </Item>

        <Item flex alignItems="flex-start" style={{ marginTop: '10px' }}>
          <Text align="left" fontSize="20px" color="#000000">
            Sélectionnez les formats disponible pour cette marque
          </Text>
        </Item>

        <Item flex alignItems="flex-start" style={{ marginTop: '5px' }}>
          <Text align="left" fontSize="12px" color="rgba(0, 0, 0, 0.5)">
            Attention si vous ne sélectionnez aucun format, par défault tous les
            formats s'afficheront pour l'utilisateur final.
          </Text>
        </Item>
      </Row>
      <Row justifyContent="space-between" style={{ marginTop: '10px' }}>
        <Row
          xs={8}
          direction="column"
          alignItems="flex-start"
          spacing={0}
          style={{
            maxHeight: '600px',
            overflow: 'auto',
            flexWrap: 'nowrap',
            justifyContent: 'flex-start',
            position: 'relative'
          }}
        >
          {loadingFormats ? (
            <CircularProgress
              style={{ position: 'absolute', top: '50%', right: '50%' }}
            />
          ) : (
            groupedPlatforms?.map((platform, index) => (
              <Item flex style={{ alignItems: 'flex-start' }} key={index}>
                <Row direction="column" alignItems="flex-start">
                  <Item flex style={{ alignItems: 'center' }}>
                    <Checkbox
                      checked={isAllPlatformFormatSelected(platform)}
                      indeterminate={
                        isPlatformFormatSelected(platform) &&
                        !isAllPlatformFormatSelected(platform)
                      }
                      onChange={event => handleSelectPlatform(event, platform)}
                      color="primary"
                    />
                    <Text bold fontSize="16px" color={themeColors.themeV2.link}>
                      {platform.name === 'displays_and_banners_iab'
                        ? 'Displays & Banners IAB'
                        : platform.name}
                    </Text>
                  </Item>

                  {/* Special rendering for platforms part of displays_and_banners_iab */}
                  {platform.name === 'displays_and_banners_iab' && (
                    <Row
                      direction="column"
                      alignItems="flex-start"
                      style={{ margin: '0px 42px', padding: '0px' }}
                    >
                      {platform?.subcategories?.map((subcategory, subIndex) => (
                        <Item key={subIndex} flex>
                          <Row direction="column">
                            {/* Subcategory Checkbox */}
                            <Item
                              flex
                              style={{
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                              }}
                            >
                              <Checkbox
                                checked={isAllSubcategoryFormatsSelected(
                                  subcategory
                                )}
                                indeterminate={
                                  isAnySubcategoryFormatSelected(subcategory) &&
                                  !isAllSubcategoryFormatsSelected(subcategory)
                                }
                                onChange={event =>
                                  handleSelectSubcategory(event, subcategory)
                                }
                                color={themeColors.themeV2.link}
                              />
                              <Text
                                bold
                                fontSize="14px"
                                color={themeColors.themeV2.link}
                              >
                                {subcategory.name}
                              </Text>
                            </Item>
                            {/* Individual Formats */}
                            {subcategory?.formats?.map(
                              (format, formatIndex) => (
                                <Item
                                  key={formatIndex}
                                  flex
                                  style={{
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    marginLeft: '20px'
                                  }}
                                >
                                  <Checkbox
                                    checked={isFormatInSelected(format)}
                                    value={format}
                                    onChange={event =>
                                      handleSelectFormat(event, format)
                                    }
                                    color={themeColors.themeV2.link}
                                  />
                                  <Text fontSize="14px">
                                    Format {format.width}x{format.height}
                                  </Text>
                                </Item>
                              )
                            )}
                          </Row>
                        </Item>
                      ))}
                    </Row>
                  )}
                  {/* Render Formats Normally for Other Platforms */}
                  {platform.name !== 'displays_and_banners_iab' && (
                    <Row
                      direction="column"
                      alignItems="flex-start"
                      style={{ margin: '0px 42px', padding: '0px' }}
                    >
                      {platform.formats.map((format, formatIndex) => (
                        <Item
                          key={formatIndex}
                          flex
                          style={{ alignItems: 'center' }}
                          marginLeft={5}
                        >
                          <Checkbox
                            checked={isFormatInSelected(format)}
                            value={format}
                            onChange={event =>
                              handleSelectFormat(event, format)
                            }
                            color={themeColors.themeV2.link}
                          />
                          <Text fontSize="14px">
                            Format {format.width}x{format.height}
                          </Text>
                        </Item>
                      ))}
                    </Row>
                  )}
                </Row>
              </Item>
            ))
          )}
        </Row>
      </Row>
      <Row justify="flex-start">
        <Item style={{ marginTop: '20px' }} justify="flex-start">
          <Button
            small
            disabled={open}
            variant="contained"
            style={{
              padding: '9px 25px',
              borderRadius: '39px',
              marginRight: '5px'
            }}
            onClick={handleSubmit}
          >
            <Text fontSize="14px" color="white">
              Enregistrer
            </Text>
          </Button>

          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <SnackbarContent
              message="Les formats sont enregistrés avec succès"
              style={{
                backgroundColor: '#4caf50',
                color: 'white'
              }}
            />
          </Snackbar>
        </Item>
      </Row>
    </Container>
  );
}

export default FormatsList;

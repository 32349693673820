import React, { useEffect } from 'react';

import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import { ACTIVATION } from 'utils/rest';

import { Container, Item, Row } from 'components/Layout';
import { Text, HelpTooltip } from 'components/DataDisplay';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components/ModalForm';

const FeesContainer = () => {
  const { currentPresetId, canEdit } = useNavigation();
  const { adminRights } = useUser();
  const [rows, setRows] = React.useState([]);
  const [fees, setFeesList] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [selectedFee, setSelectedFee] = React.useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectFee = id => {
    const media = fees.find(el => el._id === id);
    setSelectedFee(media);
  };
  const closeModal = () => {
    setModal(null);
    selectFee({});
  };
  const getFees = async () => {
    try {
      const result = await ACTIVATION.getFees(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectFee(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = datas => {
    ACTIVATION.updateFees(selectedFee._id, datas).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  if (!loaded) {
    getFees().then(resp => {
      setLoaded(true);
      setFeesList(resp);
      const mapped = resp.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.key = item.key;
        newItem.technicalFees = numeral(item.technicalFees).format('0[.]00%');
        newItem.setUpFees = item.setUpFees;
        newItem.weeklyFees = item.weeklyFees;
        newItem.dataValorization = numeral(item.dataValorization).format(
          '0[.]00%'
        );
        newItem.dataCpm = item.dataCpm;
        return newItem;
      });
      setRows(mapped);
    });
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Groupe',
                  id: 'key',
                  sortKey: 'key'
                },
                {
                  label: 'Frais techniques',
                  id: 'technicalFees'
                },
                {
                  label: 'Frais de set up (€)',
                  id: 'setUpFees'
                },
                {
                  label: 'Frais de trading (€)',
                  id: 'weeklyFees'
                },
                {
                  label: 'Valorisation de la data',
                  id: 'dataValorization'
                },
                {
                  label: (
                    <Item>
                      <Text>CPM data</Text>&nbsp;
                      <HelpTooltip title="Ce CPM est utile pour le calcul de la boucle de retour data. Aucun frais data ne sera appliqué lors de vos campagnes CRM." />
                    </Item>
                  ),
                  id: 'dataCpm'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={canEdit && openUpdateModal}
              onView={!canEdit && openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={selectedFee}
              actionName="Modifier le média"
              update
              canEdit={canEdit}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default FeesContainer;

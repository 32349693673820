import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  keyError: false,
  technicalFeesError: false,
  setUpFeesError: false,
  weeklyFeesError: false,
  dataValorizationError: false
};

export const ModalForm = props => {
  const { onValidate, config, actionBeta, update, canEdit, onClose } = props;
  const initialState = {
    key: config.key || null,
    technicalFees: config.technicalFees * 100 || 0,
    setUpFees: config.setUpFees || 0,
    weeklyFees: config.weeklyFees || null,
    dataValorization: config.dataValorization * 100 || null,
    dataCpm: config.dataCpm || null
  };
  const [
    { key, technicalFees, setUpFees, weeklyFees, dataValorization, dataCpm },
    setState
  ] = React.useState(initialState);
  const [
    {
      keyError,
      technicalFeesError,
      setUpFeesError,
      weeklyFeesError,
      dataValorizationError
    },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = (field, itemKey) => value => {
    const text = value.trim();
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!key) {
      toggleError('key', true);
      error = true;
    }
    if (typeof technicalFees === 'undefined') {
      toggleError('technicalFees', true);
      error = true;
    }
    if (typeof setUpFees === 'undefined' || setUpFees === '') {
      toggleError('setUpFees', true);
      error = true;
    }
    if (typeof weeklyFees === 'undefined') {
      toggleError('weeklyFees', true);
      error = true;
    }
    if (typeof dataValorization === 'undefined') {
      toggleError('dataValorization', true);
      error = true;
    }

    if (!error) {
      const datas = {
        key,
        technicalFees: technicalFees / 100,
        setUpFees,
        weeklyFees,
        dataValorization: dataValorization / 100,
        dataCpm
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={canEdit ? doAction : onClose}
            >
              {canEdit ? actionBeta || 'Valider' : 'Fermer'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Groupe"
                            small
                            value={key}
                            disabled={!canEdit || update}
                            onChange={handleChange('text', 'key')}
                            error={keyError}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} />
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Frais" xs={2}>
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Frais techniques"
                            required
                            small
                            value={technicalFees}
                            onChange={handleChange('rate', 'technicalFees')}
                            error={technicalFeesError}
                            format="numberDec"
                            unit="%"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Frais de set up"
                            required
                            small
                            value={setUpFees}
                            onChange={handleChange('text', 'setUpFees')}
                            error={setUpFeesError}
                            format="numberDec"
                            unit="€"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="Frais de trading"
                            required
                            small
                            value={weeklyFees}
                            onChange={handleChange('text', 'weeklyFees')}
                            error={weeklyFeesError}
                            format="numberDec"
                            unit="€"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            small
                            title="Valorisation de la data"
                            required
                            value={dataValorization}
                            onChange={handleChange('rate', 'dataValorization')}
                            error={dataValorizationError}
                            format="numberDec"
                            unit="%"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="CPM Data"
                            tooltip="Ce CPM est utile pour le calcul de la boucle de retour data. Aucun frais data ne sera appliqué lors de vos campagnes CRM."
                            small
                            value={dataCpm}
                            format="numberDec"
                            onChange={handleChange('text', 'dataCpm')}
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null,
  update: false
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any),
  update: PropTypes.bool
};

export default ModalForm;

/* eslint-disable camelcase */
import React, { useEffect } from 'react';

import { useUser } from 'contexts/UserContext';

import { ACTIVATION } from 'utils/rest';

import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components';

const MeasuresContainer = () => {
  const { currentPresetId } = useNavigation();
  const { adminRights, isSuperAdmin } = useUser();
  const [rows, setRows] = React.useState([]);
  const [measures, setMeasuresList] = React.useState([]);
  const [conversionRatesList, setConversionRates] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [selectedMeasure, setSelectedMeasure] = React.useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectMeasure = id => {
    const selected = measures.find(el => el._id === id);
    setSelectedMeasure(selected);
  };
  const closeModal = () => {
    setModal(null);
    selectMeasure({});
  };
  const getMeasures = async () => {
    try {
      const result = await ACTIVATION.getMeasures(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getConversionRates = async () => {
    try {
      const result = await ACTIVATION.getConversionRates(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectMeasure(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = datas => {
    ACTIVATION.updateMeasures(selectedMeasure._id, datas).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  if (!loaded) {
    Promise.all([getMeasures(), getConversionRates()]).then(resp => {
      setLoaded(true);
      setMeasuresList(resp[0]);
      setConversionRates(resp[1]);
      const mapped = resp[0].map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.key = item.key;
        newItem.target = item.target;
        newItem.volume1 = item.volumes.volume1;
        newItem.volume2 = item.volumes.volume2;
        newItem.volume3 = item.volumes.volume3;
        newItem.volume4 = item.volumes.volume4;
        newItem.active = item.active ? 'actif' : 'non actif';
        newItem.omnicanal = item.omnicanal ? 'omnicanal' : 'web';
        newItem.cr2_3 = item.conversionRates.cr2_3?.label;
        newItem.cr3_4 = item.conversionRates.cr3_4?.label;
        return newItem;
      });
      setRows(mapped);
    });
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'key',
                  id: 'key',
                  sortKey: 'key'
                },
                {
                  label: 'Label',
                  id: 'target',
                  sortKey: 'target'
                },
                {
                  label: 'Etape 1',
                  id: 'volume1',
                  sortKey: 'volume1'
                },
                {
                  label: 'Etape 2',
                  id: 'volume2',
                  sortKey: 'volume2'
                },
                {
                  label: 'Etape 3',
                  id: 'volume3',
                  sortKey: 'volume3'
                },
                {
                  label: 'Etape 4',
                  id: 'volume4',
                  sortKey: 'volume4'
                },
                {
                  label: 'Actif',
                  id: 'active',
                  sortKey: 'active'
                },
                {
                  label: 'Type de funnel',
                  id: 'omnicanal',
                  sortKey: 'omnicanal'
                },
                {
                  label: 'Taux de conversion 3/2',
                  id: 'cr2_3',
                  sortKey: 'cr2_3'
                },
                {
                  label: 'Taux de conversion 4/3',
                  id: 'cr3_4',
                  sortKey: 'cr3_4'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={isSuperAdmin && openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ ...selectedMeasure, conversionRatesList }}
              actionName="Modifier le média"
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default MeasuresContainer;

import React, { useEffect, useState } from 'react';

import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import pluralize from 'utils/pluralize';
import { CATEGORY, GROUP, PRICING } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text, Tooltip } from 'components/DataDisplay';
import { FormSection } from 'components/FormSection';
import { AutocompleteTextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { ModalDelete } from 'components/ModalDelete';
import { SearchField } from 'components/SearchField';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { ModalForm, ModalToDefault } from './components';

const PricingContainer = () => {
  const { adminRights, user } = useUser();
  const { themeColors } = useTheme();

  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState('');
  const [refreshGroupPricing, setRefreshGroupPricing] = useState(false);
  const [defaultRows, setDefaultRows] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupPricings, setSelectedGroupPricings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allPricings, setAllPricings] = useState([]);
  const [isDefaultPricing, setIsDefaultPricing] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState({});
  const isSuperAdmin = user.roles.includes('SUPERADMIN');
  const [otherPricingsCategories, setOtherPricingsCategories] = useState([]);

  const getToolTipText = txt => {
    return (
      <Row spacing={0}>
        <Item>
          <Text color={themeColors.dark}>{txt}</Text>
        </Item>
      </Row>
    );
  };

  const getCategoriesNames = (tbKeys, tbCategs) => {
    if (tbKeys === null) {
      return [];
    }
    return tbKeys.map(
      key => tbCategs.find(({ key: k }) => k === key)?.label || null
    );
  };

  const formatPricingCriteria = list => {
    const nbCategories = list.length;

    if (nbCategories === 0) {
      return <Text>Aucune</Text>;
    }
    const str = `${nbCategories} ${pluralize('catégorie', nbCategories)}`;

    const tooltipContent = () => {
      return (
        <Container>
          <Row spacing={0}>
            <Item>{list.map(el => getToolTipText(el))}</Item>
          </Row>
        </Container>
      );
    };
    return (
      <Container>
        <Row spacing={0}>
          <Item>
            <Tooltip title={tooltipContent()}>
              <Text color="global">{str}</Text>
            </Tooltip>
          </Item>
        </Row>
      </Container>
    );
  };

  const handleCreatePricing = data => {
    PRICING.createPricing(data).then(() => {
      setModal(null);
      setLoaded(false);
    });
  };

  const selectPricing = id => {
    const pricing = [...defaultRows, ...selectedGroupPricings].find(
      el => el._id === id
    );
    setSelectedPricing(pricing);
    setOtherPricingsCategories(
      allPricings
        .filter(
          el =>
            el.groupId === selectedPricing?.groupId &&
            el._id !== selectedPricing._id
        )
        .map(el => el.criteriaCategories)
        .flat()
    );
  };

  const closeModal = () => {
    setModal(null);
    setSelectedPricing({});
    setOtherPricingsCategories([]);
  };

  const addPricing = () => {
    if (adminRights?.general?.tarification?.create && isSuperAdmin) {
      setModal('modalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const handleUpdate = (id, isDefault) => {
    setIsDefaultPricing(isDefault);
    const temporarySelectedPricing = allPricings.find(
      pricing => pricing._id === id
    );
    setSelectedPricing(temporarySelectedPricing);
    const otherPricingsCategories = allPricings
      .filter(
        el =>
          el.groupId === temporarySelectedPricing?.groupId &&
          el._id !== temporarySelectedPricing._id
      )
      .map(el => el.criteriaCategories)
      .flat();
    setOtherPricingsCategories(otherPricingsCategories);
    setModal('modalUpdate');
  };

  const getPricings = async (groupId = null, type = null) => {
    try {
      const result = await PRICING.getPricings(groupId, type);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getAllGroups = async () => {
    try {
      const result = await GROUP.getGroups();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const filterRows = newRows => {
    setDefaultRows(newRows);
  };

  const handleSelectedGroup = group => {
    setSelectedGroup(group);
    setRefreshGroupPricing(true);
  };

  const getDatas = async () => {
    const [pricings, allGroups, allCategories] = await Promise.all([
      getPricings(null, 'default'),
      getAllGroups(),
      CATEGORY.getFullCategoryList()
    ]);
    setGroups(allGroups);
    const mappedCategories = allCategories.reduce((acc, el) => {
      if (el.parentKey) {
        acc.push({
          key: el.key,
          id: el._id,
          label: el.name
        });
      }
      return acc;
    }, []);
    setCategories(mappedCategories);
    if (pricings.length) {
      const defaultPricings = pricings.filter(
        pricing => pricing.type === 'default'
      );
      const mappedDefaultPricings = defaultPricings.reduce((acc, el) => {
        const defaultPricing = {
          ...el,
          // cpmCampaignBoth: `${el.cpmCrm}/${el.cpmCampaign}`,
          criteriaCategories: formatPricingCriteria(
            getCategoriesNames(el.criteriaCategories, mappedCategories)
          ),
          criteriaCategoriesArray: el.criteriaCategories
        };
        acc.push(defaultPricing);
        return acc;
      }, []);

      const formatedPricings = pricings.reduce((acc, el) => {
        const defaultPricing = defaultRows.find(dp => dp.key === el.key);
        const formatedPricing = {
          ...el,
          name: el.name || defaultPricing.name,
          cpmCampaign:
            el.cpmCampaign !== null
              ? el.cpmCampaign
              : defaultPricing.cpmCampaign,
          cpmCrm: el.cpmCrm !== null ? el.cpmCrm : defaultPricing.cpmCrm,
          cpmEnrichment:
            el.cpmEnrichment !== null
              ? el.cpmEnrichment
              : defaultPricing.cpmEnrichment,
          cpmExport:
            el.cpmExport !== null ? el.cpmExport : defaultPricing.cpmExport,
          criteriaCategories: el?.criteriaCategories
            ? el.criteriaCategories
            : defaultPricing?.criteriaCategoriesArray || []
        };
        acc.push(formatedPricing);
        return acc;
      }, []);
      setDefaultRows(mappedDefaultPricings);
      setAllRows(mappedDefaultPricings);
      setAllPricings(formatedPricings);
      setRows(pricings);
    } else {
      setAllRows([]);
      setRows([]);
    }
    setRefreshGroupPricing(true);
  };

  const getGroupPricings = async (groupId, tbCategs) => {
    try {
      const groupPricings = await getPricings(groupId);
      if (groupPricings.length && defaultRows.length) {
        const mappedResults = groupPricings.reduce((acc, groupPricing) => {
          const defaultPricing = defaultRows.find(
            dp => dp.key === groupPricing.key
          );

          if (defaultPricing) {
            const mappedNames = getCategoriesNames(
              groupPricing.criteriaCategories,
              tbCategs
            );
            const updatedPricing = {
              ...groupPricing,
              name: {
                value: groupPricing.name || defaultPricing.name,
                fromGroup: !!groupPricing.name
              },
              cpmCampaign: {
                value:
                  groupPricing.cpmCampaign !== null
                    ? groupPricing.cpmCampaign
                    : defaultPricing.cpmCampaign,
                fromGroup: groupPricing.cpmCampaign !== null
              },
              // cpmCampaignBoth: {
              //   cpmCampaign: {
              //     value:
              //       groupPricing.cpmCampaign !== null
              //         ? groupPricing.cpmCampaign
              //         : defaultPricing.cpmCampaign,
              //     fromGroup: groupPricing.cpmCampaign !== null
              //   },
              //   cpmCrm: {
              //     value:
              //       groupPricing.cpmCrm !== null
              //         ? groupPricing.cpmCrm
              //         : defaultPricing.cpmCrm,
              //     fromGroup: groupPricing.cpmCrm !== null
              //   }
              // },
              cpmEnrichment: {
                value:
                  groupPricing.cpmEnrichment !== null
                    ? groupPricing.cpmEnrichment
                    : defaultPricing.cpmEnrichment,
                fromGroup: groupPricing.cpmEnrichment !== null
              },
              cpmExport: {
                value:
                  groupPricing.cpmExport !== null
                    ? groupPricing.cpmExport
                    : defaultPricing.cpmExport,
                fromGroup: groupPricing.cpmExport !== null
              },
              criteriaCategories: {
                value: groupPricing.criteriaCategories
                  ? formatPricingCriteria(mappedNames)
                  : defaultPricing.criteriaCategories,
                fromGroup: !!groupPricing.criteriaCategories
              }
            };
            acc.push(updatedPricing);
          } else {
            acc.push({
              ...groupPricing,
              name: { value: groupPricing.name, fromGroup: true },
              // cpmCampaignBoth: {
              //   cpmCampaign: {
              //     value: groupPricing.cpmCampaign,
              //     fromGroup: true
              //   },
              //   cpmCrm: { value: groupPricing.cpmCrm, fromGroup: true }
              // },
              cpmCampaign: {
                value: groupPricing.cpmCampaign,
                fromGroup: true
              },
              cpmEnrichment: {
                value: groupPricing.cpmEnrichment,
                fromGroup: true
              },
              cpmExport: { value: groupPricing.cpmExport, fromGroup: true },
              criteriaCategories: {
                value: formatPricingCriteria(groupPricing.criteriaCategories),
                fromGroup: true
              }
            });
          }

          return acc;
        }, []);

        const formatedPricings = groupPricings.reduce((acc, el) => {
          const defaultPricing = defaultRows.find(dp => dp.key === el.key);
          const formatedPricing = {
            ...el,
            name: el.name || defaultPricing.name,
            cpmCampaign:
              el.cpmCampaign !== null
                ? el.cpmCampaign
                : defaultPricing.cpmCampaign,
            cpmCrm: el.cpmCrm !== null ? el.cpmCrm : defaultPricing.cpmCrm,
            cpmEnrichment:
              el.cpmEnrichment !== null
                ? el.cpmEnrichment
                : defaultPricing.cpmEnrichment,
            cpmExport:
              el.cpmExport !== null ? el.cpmExport : defaultPricing.cpmExport,
            criteriaCategories: el?.criteriaCategories
              ? el.criteriaCategories
              : defaultPricing?.criteriaCategoriesArray || []
          };
          acc.push(formatedPricing);
          return acc;
        }, []);
        setAllPricings(prev => [...prev, ...formatedPricings]);
        setSelectedGroupPricings(mappedResults);
      } else {
        setSelectedGroupPricings(groupPricings);
      }
    } catch (err) {
      console.error(err);
      setSelectedGroupPricings([]);
    } finally {
      setLoaded(true);
      setRefreshGroupPricing(false);
    }
  };

  const updateGroupPricing = async () => {
    const datas = selectedGroupPricings.reduce((acc, el) => {
      const body = {
        _id: el._id,
        name: null,
        cpmCampaign: null,
        cpmCrm: null,
        cpmEnrichment: null,
        cpmExport: null,
        criteriaCategories: []
      };
      acc.push(body);
      return acc;
    }, []);
    await PRICING.updateManyPricings(datas);
    closeModal();
    setLoaded(false);
  };

  useEffect(() => {
    if (refreshGroupPricing) {
      if (selectedGroup?._id) {
        getGroupPricings(selectedGroup._id, categories);
      } else {
        setRefreshGroupPricing(false);
        setLoaded(true);
      }
    }

    // eslint-disable-next-line
  }, [selectedGroup, refreshGroupPricing]);

  const handleDelete = id => {
    const temporarySelectedPricing = rows.find(pricing => pricing._id === id);
    setSelectedPricing(temporarySelectedPricing);
    allPricings
      .filter(
        el =>
          el.groupId === selectedPricing?.groupId &&
          el._id !== selectedPricing._id
      )
      .map(el => el.criteriaCategories)
      .flat();
    setModal('modalDelete');
  };

  const compareObjectArray = (arr1, arr2) => {
    if (arr1?.length !== arr2?.length) return true;

    return arr1?.some((obj1, index) => {
      const obj2 = arr2[index];
      return JSON.stringify(obj1) !== JSON.stringify(obj2);
    });
  };

  const handleUpdatePricing = async data => {
    let payload = {
      _id: data._id
    };
    if (!isDefaultPricing) {
      const defaultPricing = defaultRows.find(
        pricing => pricing.key === data.key
      );

      if (data.name !== defaultPricing.name) {
        payload.name = data.name || null;
      } else {
        payload.name = '' || null;
      }
      if (Number(data.cpmCampaign) !== Number(defaultPricing.cpmCampaign)) {
        payload.cpmCampaign = data.cpmCampaign;
      } else {
        payload.cpmCampaign = null;
      }
      if (Number(data.cpmCrm) !== Number(defaultPricing.cpmCrm)) {
        payload.cpmCrm = data.cpmCrm;
      } else {
        payload.cpmCrm = null;
      }
      if (Number(data.cpmEnrichment) !== Number(defaultPricing.cpmEnrichment)) {
        payload.cpmEnrichment = data.cpmEnrichment;
      } else {
        payload.cpmEnrichment = null;
      }
      if (Number(data.cpmExport) !== Number(defaultPricing.cpmExport)) {
        payload.cpmExport = data.cpmExport;
      } else {
        payload.cpmExport = null;
      }
      if (compareObjectArray(data.channels, defaultPricing.channels)) {
        payload.channels = data.channels;
      } else {
        payload.channels = null;
      }
      if (compareObjectArray(data.medias, defaultPricing.medias)) {
        payload.medias = data.medias;
      } else {
        payload.medias = null;
      }
      if (
        data.criteriaCategories?.length !==
          defaultPricing.criteriaCategoriesArray?.length ||
        !data.criteriaCategories?.every(el =>
          defaultPricing?.criteriaCategoriesArray?.includes(el)
        )
      ) {
        const duplicatedCategories = data.criteriaCategories.filter(el =>
          otherPricingsCategories.includes(el)
        );
        if (duplicatedCategories.length) {
          const otherPricings = allPricings.filter(
            el =>
              el.groupId === selectedPricing?.groupId &&
              el._id !== selectedPricing?._id
          );
          for (const pricing of otherPricings) {
            if (
              pricing.criteriaCategories.some(el =>
                duplicatedCategories.includes(el)
              )
            ) {
              const newPricing = {
                _id: pricing._id,
                criteriaCategories: pricing.criteriaCategories.filter(
                  el => !duplicatedCategories.includes(el)
                )
              };
              await PRICING.updatePricing(newPricing._id, newPricing);
            }
          }
        }
        payload.criteriaCategories = data.criteriaCategories;
      } else {
        payload.criteriaCategories = null;
      }
    } else {
      payload = data;
    }
    if (!payload.name) {
      await PRICING.updateManyPricings([payload]);
    } else {
      await PRICING.updatePricing(payload._id, payload);
    }
    closeModal();
    setLoaded(false);
  };

  const onDelete = async () => {
    await PRICING.deletePricing(selectedPricing._id);
    closeModal();
    setLoaded(false);
  };

  useEffect(() => {
    if (!loaded) {
      getDatas();
    }
    // eslint-disable-next-line
  }, [loaded]);

  if (!loaded) {
    return (
      <Container>
        <Loading loading />
      </Container>
    );
  }

  return (
    <Container>
      <Row spacing={1}>
        <Item>
          <FormSection title="Tarification par défaut" xs={3}>
            <Row>
              <Item xs={3}>
                <SearchField
                  onChange={filterRows}
                  datas={allRows}
                  titleHead="Recherche une tarification"
                  placeholder="Nom, Key..."
                />
              </Item>
              <Item justify="flex-end" xs>
                <ButtonCreate
                  onClick={addPricing}
                  text="Ajouter une tarification"
                />
                {openModal === 'modalCreate' && (
                  <ModalForm
                    open={openModal === 'modalCreate'}
                    onClose={closeModal}
                    onValidate={handleCreatePricing}
                    actionName="Créer"
                    data={{
                      ...selectedPricing,
                      categoriesList: categories
                    }}
                  />
                )}
              </Item>
            </Row>
            <Row>
              <Item>
                <TableData
                  rows={defaultRows}
                  headers={[
                    { label: 'Nom', id: 'name', sortKey: 'name' },
                    { label: 'Key', id: 'key', sortKey: 'key' },
                    {
                      label: 'Catégories de critères',
                      id: 'criteriaCategories'
                    },
                    {
                      label: 'CPM enrichissement',
                      id: 'cpmEnrichment',
                      sortKey: 'cpmEnrichment'
                    },
                    {
                      label: 'CPM campagne médias',
                      id: 'cpmCampaign',
                      sortKey: 'cpmCampaign'
                    },
                    // {
                    //   label: 'CPM campagne',
                    //   id: 'cpmCampaignBoth',
                    //   help: 'CPM campagne CRM / CPM campagne médias'
                    // },
                    {
                      label: 'CPM export',
                      id: 'cpmExport',
                      sortKey: 'cpmExport'
                    }
                  ]}
                  onUpdate={isSuperAdmin ? e => handleUpdate(e, true) : null}
                  onDelete={
                    isSuperAdmin
                      ? e => {
                          selectPricing(e);
                          if (!isSuperAdmin) {
                            setModal('unauthorized');
                            return;
                          }
                          handleDelete(e);
                        }
                      : null
                  }
                />
              </Item>
            </Row>
          </FormSection>
        </Item>
      </Row>
      <Row spacing={1}>
        <Item>
          <FormSection title="Personnalisation organisation" xs={3}>
            <Container justify="flex-start" style={{ marginLeft: '25px' }}>
              <Row
                style={{ maxWidth: '350px', marginTop: '6px' }}
                justify="flex-start"
              >
                <Item xs={10} justify="flex-start">
                  <AutocompleteTextField
                    title="Organisation"
                    placeholder="Choisissez une organisation"
                    options={groups.map(el => ({
                      ...el,
                      label: el.name,
                      value: el
                    }))}
                    formatLabel={option => {
                      return `${option.name} (${option._id})`;
                    }}
                    small
                    value={selectedGroup}
                    onChange={e => handleSelectedGroup(e)}
                  />
                </Item>
              </Row>
              <Item justify="flex-end" xs style={{ marginTop: '40px' }}>
                <ButtonCreate
                  onClick={() => {
                    setModal('modalUpdateGroup');
                  }}
                  text="Réinitialiser"
                  reload
                />
              </Item>
            </Container>
            {selectedGroupPricings.length > 0 && (
              <Row>
                <Item>
                  <TableData
                    rows={selectedGroupPricings.map(row => ({
                      ...row,
                      name: (
                        <Text
                          style={{
                            fontWeight: row.name.fromGroup ? 'bold' : 'normal'
                          }}
                        >
                          {row.name.value}
                        </Text>
                      ),
                      // cpmCampaignBoth: (
                      //   <span>
                      //     <span
                      //       style={{
                      //         fontWeight: row.cpmCampaignBoth.cpmCrm.fromGroup
                      //           ? 'bold'
                      //           : 'normal'
                      //       }}
                      //     >
                      //       {row.cpmCampaignBoth.cpmCrm.value}
                      //     </span>
                      //     {' / '}
                      //     <span
                      //       style={{
                      //         fontWeight: row.cpmCampaignBoth.cpmCampaign
                      //           .fromGroup
                      //           ? 'bold'
                      //           : 'normal'
                      //       }}
                      //     >
                      //       {row.cpmCampaignBoth.cpmCampaign.value}
                      //     </span>
                      //   </span>
                      // ),
                      cpmCampaign: (
                        <Text
                          style={{
                            fontWeight: row.cpmCampaign.fromGroup
                              ? 'bold'
                              : 'normal'
                          }}
                        >
                          {row.cpmCampaign.value}
                        </Text>
                      ),
                      cpmEnrichment: (
                        <Text
                          style={{
                            fontWeight: row.cpmEnrichment.fromGroup
                              ? 'bold'
                              : 'normal'
                          }}
                        >
                          {row.cpmEnrichment.value}
                        </Text>
                      ),
                      cpmExport: (
                        <Text
                          style={{
                            fontWeight: row.cpmExport.fromGroup
                              ? 'bold'
                              : 'normal'
                          }}
                        >
                          {row.cpmExport.value}
                        </Text>
                      ),
                      criteriaCategories: (
                        <Text
                          style={{
                            fontWeight: row.criteriaCategories.fromGroup
                              ? 'bold'
                              : 'normal'
                          }}
                        >
                          {row.criteriaCategories.value}
                        </Text>
                      )
                    }))}
                    headers={[
                      { label: 'Nom', id: 'name', sortKey: 'name' },
                      { label: 'Key', id: 'key', sortKey: 'key' },
                      {
                        label: 'Catégories de critères',
                        id: 'criteriaCategories'
                      },
                      {
                        label: 'CPM enrichissement',
                        id: 'cpmEnrichment',
                        sortKey: 'cpmEnrichment'
                      },
                      {
                        label: 'CPM campagne médias',
                        id: 'cpmCampaign',
                        sortKey: 'cpmCampaign'
                      },
                      // {
                      //   label: 'CPM campagne',
                      //   id: 'cpmCampaignBoth',
                      //   help: 'CPM campagne CRM / CPM campagne médias'
                      // },
                      {
                        label: 'CPM export',
                        id: 'cpmExport',
                        sortKey: 'cpmExport'
                      }
                    ]}
                    onUpdate={
                      adminRights?.general.customers.advanced
                        ? e => handleUpdate(e, false)
                        : null
                    }
                  />
                </Item>
              </Row>
            )}
          </FormSection>
        </Item>
      </Row>
      {openModal === 'modalCreate' && (
        <ModalForm
          open={openModal === 'modalCreate'}
          onClose={closeModal}
          onValidate={handleCreatePricing}
          data={{ categoriesList: categories }}
          actionName="Créer"
        />
      )}
      {openModal === 'modalUpdate' && (
        <ModalForm
          open={openModal === 'modalUpdate'}
          onClose={closeModal}
          onValidate={handleUpdatePricing}
          data={{
            categoriesList: categories,
            ...selectedPricing
          }}
          actionName="Créer"
          otherPricingsCategories={otherPricingsCategories}
        />
      )}
      {openModal === 'modalDelete' && (
        <ModalDelete
          name={selectedPricing.name}
          type="cette tarification"
          open={openModal === 'modalDelete'}
          onClose={closeModal}
          onDelete={() => {
            onDelete();
          }}
        />
      )}
      {openModal === 'modalUpdateGroup' && (
        <ModalToDefault
          open={openModal === 'modalUpdateGroup'}
          onClose={closeModal}
          onConfirm={updateGroupPricing}
        />
      )}
      {openModal === 'unauthorized' && (
        <UnauthorizedModal
          open={openModal === 'unauthorized'}
          onClose={closeModal}
        />
      )}
    </Container>
  );
};

export default PricingContainer;

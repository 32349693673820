export const getSetupCampaignsStatus = ({
  selectedIds = [],
  allCampaignConfigs = []
}) => {
  if (selectedIds.length === 0) {
    return false;
  }
  return selectedIds.some(selectedId => {
    const campaignConfigFineded = allCampaignConfigs.find(
      campaignConfig => campaignConfig.id === selectedId
    );
    if (campaignConfigFineded) {
      const { setup = [] } = campaignConfigFineded;
      if (setup.length > 0) {
        return !setup.some(el => el.status === 'done' || el.status === 'error');
      }
      return false;
    }
    return true;
  });
};

export const getDataForCampaignUpdate = ({
  selectedIds,
  allCampaignConfigs
}) => {
  const allCampaignSelected = allCampaignConfigs.filter(campaignConfig =>
    selectedIds.includes(campaignConfig._id)
  );

  const data = allCampaignSelected.reduce(
    (acc, campaignConfig) => {
      if (acc.start !== campaignConfig.start) {
        acc.start = null;
      }
      if (acc.end !== campaignConfig.end) {
        acc.end = null;
      }
      if (acc.status !== campaignConfig.state) {
        acc.status = null;
      }
      if (acc.objective !== campaignConfig.objective) {
        acc.objective = null;
      }
      if (acc.paid !== campaignConfig.paid) {
        acc.paid = null;
      }
      return acc;
    },
    {
      start: allCampaignSelected[0].start,
      end: allCampaignSelected[0].end,
      status: allCampaignSelected[0].state,
      objective: allCampaignSelected[0].objective,
      paid: allCampaignSelected[0].paid
    }
  );
  return data;
};

export const getLabel = (mediaOrder, item) => {
  if (!mediaOrder?.objective) {
    return null;
  }
  // eslint-disable-next-line prefer-destructuring
  const objectiveLabel = item?.objectives?.find(
    objective => mediaOrder.objective === objective.objectiveKey
  )?.objectiveLabel;

  let subObjectiveLabel = '';
  if (mediaOrder?.subObjective) {
    // eslint-disable-next-line prefer-destructuring
    subObjectiveLabel = item?.objectives
      .find(objective => mediaOrder.objective === objective.objectiveKey)
      ?.subObjectives.find(
        subObjective => subObjective.subObjectiveKey === mediaOrder.subObjective
      )?.subObjectiveLabel;
  }

  return subObjectiveLabel
    ? `${objectiveLabel} - ${subObjectiveLabel}`
    : `${objectiveLabel}`;
};

export default getSetupCampaignsStatus;

import axios from 'axios';

import { localStorage } from 'utils/constants';

let domain;

const updateDomain = value => {
  domain = value;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_ERMES_API,
  timeout: 1200000,
  withCredentials: false
});

api.interceptors.request.use(
  async config => {
    const newConfig = {
      data: {},
      ...config,
      headers: {
        ...config.headers,
        domain,
        authorization: window.localStorage.getItem(localStorage.ACCESS_TOKEN)
      }
    };
    return newConfig;
  },
  error => Promise.reject(error)
);

const bufferApi = axios.create({
  baseURL: process.env.REACT_APP_ERMES_API,
  withCredentials: false,
  responseType: 'arraybuffer'
});

bufferApi.interceptors.request.use(
  async config => {
    const newConfig = {
      data: {},
      ...config,
      headers: {
        ...config.headers,
        domain,
        authorization: window.localStorage.getItem(localStorage.ACCESS_TOKEN)
      }
    };
    return newConfig;
  },
  error => Promise.reject(error)
);

export { api, bufferApi, updateDomain };

export default api;

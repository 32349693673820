import { api } from './api';

const getAllUserBrandIdentity = async (page, searchValue) => {
  try {
    const { data } = await api.get(`/v2/activation/brandIdentity`, {
      params: {
        page,
        searchValue
      }
    });

    return data;
  } catch (err) {
    return [];
  }
};
const getUserBrandIdentity = async groupId => {
  try {
    const { data } = await api.get(
      `/v2/activation/brandIdentity/user/${groupId}`
    );
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const updateBrandIdentity = async (groupId, body) => {
  try {
    const { data } = await api.put(
      `/v2/activation/brandIdentity/${groupId}`,
      body
    );
    return data;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export default {
  getAllUserBrandIdentity,
  getUserBrandIdentity,
  updateBrandIdentity
};

import React, { useEffect } from 'react';

import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import { ACTIVATION } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components/ModalForm';

const EmailsCountContainer = () => {
  const { currentPresetId } = useNavigation();
  const { adminRights, isSuperAdmin } = useUser();
  const [rows, setRows] = React.useState([]);
  const [proxyKeys, setProxyKeys] = React.useState([]);
  const [medias, setMediaList] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [selectedMedia, setSelectedMedia] = React.useState({});
  const [mediaAll, setMediaAll] = React.useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectMedia = id => {
    const media = medias.find(el => el._id === id);
    setSelectedMedia(media);
  };
  const closeModal = () => {
    setModal(null);
    selectMedia({});
  };
  const getInfoContacts = async () => {
    try {
      const result = await ACTIVATION.getInfoContacts(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getOfflineMediaProxies = async () => {
    try {
      const result = await ACTIVATION.getOfflineMediaProxies();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const loadData = async () => {
    const infoContacts = await getInfoContacts();
    const contactAll = infoContacts.find(el => el.key === 'all');
    setMediaAll(contactAll);
    const mediaProxies = await getOfflineMediaProxies();
    const keys = mediaProxies.map(proxy => {
      return {
        value: proxy.key,
        label: proxy.name
      };
    });

    const mapped = infoContacts.map(item => {
      const newItem = { ...item };
      /*
      We will take the percentage of emails and emails with cookies from the database
      newItem.percentEmails = (item.nbEmails / contactAll.nbEmails) * 100;
      newItem.percentEmailsWithCookie =
        (item.nbEmailsWithCookie / contactAll.nbEmailsWithCookie) * 100;
      */
      newItem.proxyLabel = keys.find(el => el.value === item.proxyKey)?.label;
      return newItem;
    });
    setLoaded(true);
    setRows(mapped);
    setMediaList(mapped);
    setProxyKeys(keys);
  };

  const openCreateModal = () => {
    if (adminRights?.activation?.update) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectMedia(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onCreate = async item => {
    const findSameMedia = medias.find(
      el => el.key === item.key && el.proxyKey === item.proxyKey
    );
    if (findSameMedia) {
      return;
    }
    await ACTIVATION.createInfoContacts(item);
    setLoaded(false);
    closeModal();
  };

  const onUpdate = datas => {
    ACTIVATION.updateInfoContacts(selectedMedia._id, datas).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  if (!loaded) {
    loadData();
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item justify="flex-end" xs>
          <ButtonCreate
            onClick={openCreateModal}
            text="Ajouter"
            disabled={!isSuperAdmin}
          />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              onValidate={onCreate}
              proxyKeys={proxyKeys}
              mediaAll={mediaAll}
              actionName="Ajouter le média"
            />
          )}
        </Item>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Key',
                  id: 'key',
                  sortKey: 'key'
                },
                {
                  label: 'Canal',
                  id: 'label'
                },
                {
                  label: "Nombre d'emails hashés",
                  id: 'nbEmails',
                  format: value => numeral(value).format('0,0')
                },
                {
                  label: "Nombre d'emails hashés avec cookies",
                  id: 'nbEmailsWithCookie',
                  format: value => numeral(value).format('0,0')
                },
                {
                  label: 'Media Proxy',
                  id: 'proxyLabel'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={isSuperAdmin && openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={selectedMedia}
              proxyKeys={proxyKeys}
              actionName="Modifier le média"
              mediaAll={mediaAll}
              update
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default EmailsCountContainer;

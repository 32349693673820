import React, { useState } from 'react';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, MultipleSelect, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

import { flattenDataProviders } from '../../utils';

export const ModalSetup = props => {
  const {
    onValidate,
    onClose,
    actionName,
    dataProviders: dataProvidersOptions,
    presetList,
    activableMedias
  } = props;

  const { themeColors } = useTheme();
  const { domainConfig } = useApp();
  const initialState = {
    domain: '',
    name: '',
    dataProviders: [],
    excludeDataProviders: [],
    presetId: '',
    email: '',
    firstName: '',
    lastName: '',
    productAccess: [
      { product: 'ermes', access: 'unlimited' },
      { product: 'ramses', access: 'blocked' }
    ],
    selectedActivableMedias: domainConfig.activableMedias
  };

  const ErrorState = {
    domainError: false,
    presetIdError: false,
    emailError: false,
    firstNameError: false,
    lastNameError: false,
    productAccessError: false
  };

  const [domainErrorMessage, setDomainErrorMessage] = useState('');




  const [
    {
      domain,
      name,
      dataProviders,
      excludeDataProviders,
      presetId,
      selectedActivableMedias
    },
    setState
  ] = useState(initialState);

  const resetStates = () => {
    setState(initialState);
  };


  const [
    {
      domainError,
      presetIdError
    },
    setError
  ] = useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    if (itemKey === 'domain') {
      const regex = /^[a-zA-Z0-9_]+$/;
      toggleError(itemKey, !regex.test(value));
    }
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const doAction = async () => {
    const isEmptyDataProviders = dataProviders.length === 0;
    const isEmptyExcludeDataProviders = excludeDataProviders.length === 0;
    let error = false;
    if (domainError) {
      error = true;
    }
    const datas = {
      domain,
      name: name || domain,
      dataProviders: isEmptyDataProviders
        ? dataProviders
        : flattenDataProviders(dataProviders),
      excludeDataProviders: isEmptyExcludeDataProviders
        ? excludeDataProviders
        : flattenDataProviders(excludeDataProviders),
      presetId,
      activableMedias: selectedActivableMedias,
      groupTaxonomy: [
        {
          level: 'shop',
          name: 'Magasin',
          key: 'shop'
        }
      ]
    };
    setDomainErrorMessage('');
    if (!domain) {
      toggleError('domain', true);
      error = true;
    }
    if (!presetId) {
      toggleError('presetId', true);
      error = true;
    }
    if (!error) {
      try {
        await onValidate(datas);
        resetStates();
      } catch (err) {
        setDomainErrorMessage(
          'Cette key est déjà utilisée. Choisissez en une autre.'
        );
        toggleError('domain', true);
      }
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.backoffice.global}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName} le domaine
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Nouveau domaine" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start" s>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Key du domaine"
                          required
                          placeholder="ERMES"
                          value={domain}
                          error={domainError}
                          errorText={domainErrorMessage}
                          onChange={handleChange('domain')}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Nom du domaine"
                          placeholder="Ermes"
                          value={name}
                          onChange={handleChange('name')}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Sources de données" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Container>
                          <Item justify="flex-start">
                            <MultipleSelect
                              title="Inclure"
                              small
                              placeHolder="Toutes les sources de données"
                              options={dataProvidersOptions}
                              selected={dataProviders.map(el => el.key)}
                              onChange={value => {
                                handleChange('dataProviders')(value);
                              }}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Container>
                          <Item justify="flex-start">
                            <MultipleSelect
                              title="Exclure"
                              small
                              placeHolder="Aucune source de données"
                              options={dataProvidersOptions}
                              selected={excludeDataProviders.map(el => el.key)}
                              onChange={value => {
                                handleChange('excludeDataProviders')(value);
                              }}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Médias activables" xs={3}>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Item xs={11} justify="flex-start">
                    <MultipleSelect
                      small
                      required
                      selectAll
                      options={activableMedias.map(el => {
                        return { label: el.name, key: el.key };
                      })}
                      selected={selectedActivableMedias}
                      onChange={value => {
                        handleChange('selectedActivableMedias')(
                          value.map(el => el.key)
                        );
                      }}
                    />
                  </Item>
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Preset" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Container>
                          <Item justify="flex-start">
                            <Select
                              required
                              options={presetList.map(preset => ({
                                value: preset._id,
                                label: preset.name
                              }))}
                              title="Preset"
                              value={presetId}
                              error={presetIdError}
                              onChange={handleChange('presetId')}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

export default ModalSetup;

import React, { useEffect, useState } from 'react';

import { useUser } from 'contexts/UserContext';

import { ACTIVATION, USERS } from 'utils/rest';

import { ButtonCreate } from 'components/ButtonCreate';
import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components/ModalForm';

const OfflineCPMContainer = () => {
  const { currentPresetId, canEdit } = useNavigation();
  const { adminRights } = useUser();
  const [rows, setRows] = useState([]);
  const [offlineCPM, setOfflineCPMList] = useState([]);
  const [config, setConfig] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [openModal, setModal] = useState(null);
  const [selectedOfflineCPM, setSelectedOfflineCPM] = useState({});

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectOfflineCPM = id => {
    const selected = offlineCPM.find(el => el._id === id);
    setSelectedOfflineCPM(selected);
  };
  const closeModal = () => {
    setModal(null);
    selectOfflineCPM({});
  };
  const getOfflineCPM = async () => {
    try {
      const result = await ACTIVATION.getOfflineCPM(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getUsers = async () => {
    try {
      const result = await USERS.getAll();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getChannels = async () => {
    try {
      const result = await ACTIVATION.getChannels();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };
  const getMediaProxies = async () => {
    try {
      const result = await ACTIVATION.getOfflineMediaProxies();
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const onCreate = datas => {
    ACTIVATION.createOfflineCPM({ preset: currentPresetId, ...datas }).then(
      () => {
        setLoaded(false);
        closeModal();
      }
    );
  };

  const openCreateModal = () => {
    if (adminRights?.activation?.update) {
      setModal('ModalCreate');
    } else {
      setModal('unauthorized');
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectOfflineCPM(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = datas => {
    ACTIVATION.updateOfflineCPM(selectedOfflineCPM._id, datas).then(() => {
      setLoaded(false);
      closeModal();
    });
  };

  const fetchData = async () => {
    const resp = await Promise.all([
      getOfflineCPM(),
      getUsers(),
      getChannels(),
      getMediaProxies()
    ]);
    const contraints = resp[0];
    const conf = {
      users: resp[1],
      channels: resp[2],
      offlineMediaProxies: resp[3]
    };
    setOfflineCPMList(resp[0]);
    setConfig(conf);
    const mapped = contraints.map(item => {
      const newItem = {};
      newItem._id = item._id;
      newItem.offlineMediaProxy = item.offlineMediaProxy.name;
      newItem.channel = item.channel?.label;
      newItem.user =
        conf.users.find(el => el._id === item.user)?.email || 'Aucun';
      newItem.cpm = item.cpm;
      return newItem;
    });
    setRows(mapped);
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  return (
    <Container>
      <Row spacing={4}>
        <Item xs={3} />
        <Item justify="flex-end" xs>
          <ButtonCreate
            onClick={openCreateModal}
            text="Ajouter"
            disabled={!canEdit}
          />
          {openModal === 'ModalCreate' && (
            <ModalForm
              open={openModal === 'ModalCreate'}
              onClose={closeModal}
              config={config}
              onValidate={onCreate}
              actionName="Ajouter"
              canEdit={canEdit}
            />
          )}
        </Item>
      </Row>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Cible média traditionnel',
                  id: 'offlineMediaProxy',
                  sortKey: 'offlineMediaProxy'
                },
                {
                  label: 'Canal',
                  id: 'channel',
                  sortKey: 'channel'
                },
                {
                  label: 'Utilisateur (optionnel)',
                  id: 'user',
                  sortKey: 'user'
                },
                {
                  label: 'CPM (€)',
                  id: 'cpm'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={canEdit && openUpdateModal}
              onView={!canEdit && openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={{ ...selectedOfflineCPM, ...config }}
              actionName="Modifier le média"
              canEdit={canEdit}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default OfflineCPMContainer;

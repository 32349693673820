import React, { useEffect } from 'react';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { useNavigation } from '../../contexts/NavigationContext';

import SidebarActivation, {
  SideItemActivation
} from './components/SidebarActivation';

/**
 * SidebarContainer
 * @container
 * @component
 *
 */

const SidebarContainer = () => {
  const { themeColors } = useTheme();
  const { menu, setMenu } = useNavigation();
  const { adminRights, isSuperAdmin } = useUser();
  const { isSimplify } = useApp();
  const linkInfo = [
    {
      name: 'domain',
      svg: 'setting',
      subtitle: 'Edition de votre domaine',
      surname: 'Domaine',
      enabled: adminRights?.general?.domain?.read || false
    },
    {
      name: 'dashboardContent',
      svg: 'setting',
      subtitle: 'Edite la zone de communication du dashboard',
      surname: 'Dashboard Ermes',
      enabled: adminRights?.general?.dashboard?.read || false
    },
    {
      name: 'groups',
      svg: 'group',
      subtitle: 'Gestion des organisations',
      surname: 'organisations',
      enabled: adminRights?.general?.groups?.read || false
    },
    {
      name: 'customers',
      svg: 'group',
      subtitle: 'Gestion des comptes clients',
      surname: 'Clients',
      enabled: adminRights?.general?.customers?.read || false
    },
    {
      name: 'users',
      svg: 'user',
      subtitle: 'adminitration des utilisateurs',
      surname: 'utilisateurs',
      enabled: adminRights?.general?.users?.read || isSimplify || false
    },
    {
      name: 'connectorsConnexion',
      svg: 'setting',
      subtitle: 'Reglages connexion des connectors',
      surname: 'connexion',
      enabled: adminRights?.general?.connection?.read || false
    },
    {
      name: 'connectorsConfig',
      svg: 'setting',
      subtitle: 'Configuration globale des connecteurs',
      surname: 'connecteurs',
      enabled: adminRights?.general?.connector?.read || false
    },
    {
      name: 'activableMedia',
      svg: 'activableMedia',
      subtitle: 'Configuration des médias activables',
      surname: 'Médias activables',
      enabled: adminRights?.general?.activableMedias?.read || false
    },
    {
      name: 'tarification',
      svg: 'euro',
      subtitle: 'Configuration des CPM des catégories de critères',
      surname: 'Tarification des critères',
      enabled: adminRights?.general?.tarification?.read || false
    },
    {
      name: 'profile',
      svg: 'group',
      subtitle: 'Configuration des fonctionnalités par profils',
      surname: 'Gestion des profils',
      enabled: isSuperAdmin
    }
  ].filter(el => {
    if (isSimplify) {
      return el.name === 'users';
    }
    return el;
  });

  const updateMenu = linkName => {
    // Set menu only if it's different by actual menu
    if (menu !== linkName) {
      setMenu(linkName);
    }
  };

  useEffect(() => {
    updateMenu(linkInfo[0]);
    // eslint-disable-next-line
  }, [isSimplify]);

  return (
    <SidebarActivation>
      {linkInfo.map(link => {
        return (
          <SideItemActivation
            key={link.name}
            active={link.name === menu.name}
            svg={link.svg}
            svgAttrs={{
              fill: themeColors.icon,
              dataStroke: themeColors.icon,
              stroke: themeColors.icon
            }}
            subtitle={link.subtitle}
            title={link.surname.toUpperCase()}
            onClick={() => updateMenu(link)}
            hasActiveClass={link.name === menu.name}
          />
        );
      })}
    </SidebarActivation>
  );
};

export default SidebarContainer;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';

import { Text } from 'components/DataDisplay';
import { Container, Item, Row } from 'components/Layout';

import { getLabel } from '../../../../utils';

import { DropdownStyleContainer } from './style';

export const DropdownSelect = ({
  item,
  themeColors,
  setMediaOrders,
  mediaOrder
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openObjectives, setOpenObjectives] = useState({});

  const handleToggle = event => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleObjectiveToggle = key => {
    setOpenObjectives(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const handleSelection = (objectiveKey, subObjectiveKey = null) => {
    setMediaOrders(prevOrders =>
      prevOrders.map(order => {
        if (order.id === mediaOrder.id) {
          return {
            ...order,
            objective: objectiveKey,
            subObjective: subObjectiveKey
          };
        }
        return order;
      })
    );
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'dropdown-select-popover' : undefined;

  return (
    <DropdownStyleContainer themeColors={themeColors}>
      <Container style={{ width: '100%', marginRight: '5px' }}>
        <Row>
          <MenuItem onClick={handleToggle}>
            <Text className="drop-elipsis">
              {mediaOrder.objective
                ? getLabel(mediaOrder, item)
                : item.campaignObjectiveLabel}
            </Text>
            {open ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <Container
              style={{
                padding: '16px',
                minWidth: '180px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              {item.objectives.map(objective => (
                <Row
                  style={{ paddingLeft: '0', paddingTop: '0' }}
                  key={objective.objectiveKey}
                >
                  <Item
                    style={{
                      flexDirection: 'column',
                      alignItems: 'flex-start'
                    }}
                  >
                    <MenuItem
                      onClick={() =>
                        objective.subObjectives.length === 0
                          ? handleSelection(objective.objectiveKey)
                          : handleObjectiveToggle(objective.objectiveKey)
                      }
                    >
                      <Text>{objective.objectiveLabel}</Text>
                      {objective.subObjectives.length > 0 &&
                        (openObjectives[objective.objectiveKey] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        ))}
                    </MenuItem>
                    {objective.subObjectives.length > 0 && (
                      <Collapse
                        in={openObjectives[objective.objectiveKey]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Container
                          style={{
                            paddingLeft: '24px',
                            paddingTop: '4px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                          }}
                        >
                          {objective.subObjectives.map(subObjective => (
                            <MenuItem
                              key={subObjective.subObjectiveKey}
                              style={{ paddingLeft: '8px' }}
                              onClick={() =>
                                handleSelection(
                                  objective.objectiveKey,
                                  subObjective.subObjectiveKey
                                )
                              }
                            >
                              <Text>{subObjective.subObjectiveLabel}</Text>
                            </MenuItem>
                          ))}
                        </Container>
                      </Collapse>
                    )}
                  </Item>
                </Row>
              ))}
            </Container>
          </Popover>
        </Row>
      </Container>
    </DropdownStyleContainer>
  );
};

DropdownSelect.propTypes = {
  item: PropTypes.shape({
    platformLabel: PropTypes.string.isRequired,
    objectives: PropTypes.arrayOf(
      PropTypes.shape({
        objectiveKey: PropTypes.string.isRequired,
        objectiveLabel: PropTypes.string.isRequired,
        subObjectives: PropTypes.arrayOf(
          PropTypes.shape({
            subObjectiveKey: PropTypes.string.isRequired,
            subObjectiveLabel: PropTypes.string.isRequired
          })
        )
      })
    ).isRequired
  }).isRequired,
  setMediaOrders: PropTypes.func.isRequired,
  mediaOrder: PropTypes.string.isRequired
};

export default DropdownSelect;

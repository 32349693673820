import { api } from './api';

const get = async () => {
  try {
    const { data } = await api.get(`/geoInterface/all`);
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default {
  get
};

import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

const ErrorState = {
  channelError: false,
  cpmError: false,
  offlineMediaProxyError: false,
  userError: false
};

export const ModalForm = props => {
  const { onValidate, config, actionBeta, canEdit, onClose } = props;
  const initialState = {
    channel: config?.channel?.label || null,
    cpm: config.cpm,
    offlineMediaProxy: config?.offlineMediaProxy?.name || null,
    user: config.users.find(el => el._id === config.user)?.email || 'Aucun',
    users: config.users,
    channels: config.channels,
    offlineMediaProxies: config.offlineMediaProxies
  };
  const [
    {
      channel,
      cpm,
      offlineMediaProxy,
      user,
      users,
      channels,
      offlineMediaProxies
    },
    setState
  ] = React.useState(initialState);
  const [
    { channelError, cpmError, offlineMediaProxyError },
    setError
  ] = React.useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const resetErrors = () => {
    setError(ErrorState);
  };
  const handleChange = (field, itemKey) => value => {
    const text = value.trim();
    toggleError(itemKey, !text);
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const resetStates = () => {
    setState(initialState);
  };

  const doAction = () => {
    let error = false;
    resetErrors();
    if (!channel) {
      toggleError('channel', true);
      error = true;
    }
    if (!cpm) {
      toggleError('cpm', true);
      error = true;
    }
    if (!offlineMediaProxy) {
      toggleError('offlineMediaProxy', true);
      error = true;
    }

    if (!error) {
      const datas = {
        cpm,
        offlineMediaProxy: offlineMediaProxies.find(
          el => el.name === offlineMediaProxy
        ).id,
        channel: channels.find(el => el.label === channel).id,
        user: users.find(el => el.email === user)?._id || null
      };
      onValidate(datas);
      resetStates();
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              size="medium"
              widthSize="medium"
              onClick={canEdit ? doAction : onClose}
            >
              {canEdit ? actionBeta || 'Valider' : 'Fermer'}
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal actions={getActions()} {...props}>
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Général">
              <Container>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Cible média traditionnel"
                            onChange={handleChange('text', 'offlineMediaProxy')}
                            options={offlineMediaProxies.map(el => el.name)}
                            value={offlineMediaProxy}
                            error={offlineMediaProxyError}
                            small
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Canal"
                            onChange={handleChange('text', 'channel')}
                            options={channels.map(el => el.label)}
                            value={channel}
                            error={channelError}
                            small
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
                <Row spacing={0}>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <Select
                            title="Utilisateur"
                            onChange={handleChange('text', 'user')}
                            options={['Aucun', ...users.map(el => el.email)]}
                            value={user}
                            small
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Container>
                      <Row spacing={0} justify="flex-start">
                        <Item xs={11} justify="flex-start">
                          <TextField
                            title="CPM"
                            small
                            value={cpm}
                            onChange={handleChange('text', 'cpm')}
                            error={cpmError}
                            format="numberDec"
                            unit="€"
                            disabled={!canEdit}
                          />
                        </Item>
                      </Row>
                    </Container>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalForm.defaultProps = {
  config: {},
  actionBeta: null
};
ModalForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  actionBeta: PropTypes.string,
  config: PropTypes.objectOf(PropTypes.any)
};

export default ModalForm;

import React, { useEffect } from 'react';

import { useUser } from 'contexts/UserContext';

import numeral from 'utils/numeral';
import { ACTIVATION } from 'utils/rest';

import { Container, Item, Row } from 'components/Layout';
import Loading from 'components/Loading';
import { TableData } from 'components/TableData';
import { UnauthorizedModal } from 'components/UnauthorizedModal';

import { useNavigation } from '../../../contexts/NavigationContext';

import { ModalForm } from './components';

const ConversionRatesContainer = () => {
  const { adminRights } = useUser();
  const { currentPresetId, canEdit } = useNavigation();
  const [rows, setRows] = React.useState([]);
  const [conversionRate, setConversionRateList] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [openModal, setModal] = React.useState(null);
  const [selectedConversionRate, setSelectedConversionRate] = React.useState(
    {}
  );

  useEffect(() => {
    setLoaded(false);
  }, [currentPresetId]);

  const selectConversionRate = id => {
    const media = conversionRate.find(el => el._id === id);
    setSelectedConversionRate(media);
  };
  const closeModal = () => {
    setModal(null);
    selectConversionRate({});
  };
  const getConversionRates = async () => {
    try {
      const result = await ACTIVATION.getConversionRates(currentPresetId);
      return result;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const openUpdateModal = id => {
    if (adminRights?.activation?.update) {
      selectConversionRate(id);
      setModal('ModalUpdate');
    } else {
      setModal('unauthorized');
    }
  };

  const onUpdate = datas => {
    ACTIVATION.updateConversionRates(selectedConversionRate._id, datas).then(
      () => {
        setLoaded(false);
        closeModal();
      }
    );
  };

  if (!loaded) {
    getConversionRates().then(resp => {
      setLoaded(true);
      setConversionRateList(resp);
      const mapped = resp.map(item => {
        const newItem = {};
        newItem._id = item._id;
        newItem.key = item.key;
        newItem.label = item.label;
        newItem.label_en = item.label_en;
        newItem.max = numeral(item.max).format('0[.]00%');
        newItem.min = numeral(item.min).format('0[.]00%');
        newItem.offTarget = numeral(item.offTarget).format('0[.]00%');
        newItem.onTarget = numeral(item.onTarget).format('0[.]00%');
        return newItem;
      });
      setRows(mapped);
    });
  }

  return (
    <Container>
      <Row spacing={4}>
        <Item>
          <Loading loading={!loaded} />
          {loaded && (
            <TableData
              rows={rows}
              headers={[
                {
                  label: 'Key',
                  id: 'key',
                  sortKey: 'key'
                },
                {
                  label: 'Label',
                  id: 'label',
                  sortKey: 'label'
                },
                {
                  label: 'Label_EN',
                  id: 'label_en'
                },
                {
                  label: 'Taux de conversion maximum',
                  id: 'max'
                },
                {
                  label: 'Taux de conversion minimum',
                  id: 'min'
                },
                {
                  label: 'Taux de conversion sur cible par défaut',
                  id: 'onTarget'
                },
                {
                  label: 'Taux de conversion hors cible par défaut',
                  id: 'offTarget'
                }
              ]}
              hidenFields={['_id']}
              onUpdate={canEdit && openUpdateModal}
              onView={!canEdit && openUpdateModal}
            />
          )}

          {openModal === 'ModalUpdate' && (
            <ModalForm
              open={openModal === 'ModalUpdate'}
              onClose={closeModal}
              onValidate={onUpdate}
              config={selectedConversionRate}
              actionName="Modifier"
              canEdit={canEdit}
            />
          )}
          {openModal === 'unauthorized' && (
            <UnauthorizedModal
              open={openModal === 'unauthorized'}
              onClose={closeModal}
            />
          )}
        </Item>
      </Row>
    </Container>
  );
};

export default ConversionRatesContainer;

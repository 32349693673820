import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ListIcon from '@mui/icons-material/PlaylistPlay';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Popper } from '@mui/material';

import { useTheme } from 'contexts/ThemeContext';

import Icon from 'utils/icons';
import moment from 'utils/moment';

import { Table, Text, Tooltip } from 'components/DataDisplay';
import { Button, IconButton } from 'components/Inputs';
// import CloseIcon from '@mui/icons-material/Close';
import { Container, Item, Row } from 'components/Layout';
import { Paper } from 'components/Surfaces';

/**
 * SidebarFunnel
 * @component
 *
 */

export const TableData = props => {
  const { themeColors } = useTheme();
  const {
    rows,
    onUpdate,
    onView,
    onNoteAdd,
    onListLogs,
    onDelete,
    onRefresh,
    onSendMail,
    headers,
    noHeader,
    ...rest
  } = props;
  let newHeader = null;
  if (headers) {
    newHeader = [...headers, { Label: 'IconsAction', id: 'IconsAction' }];
  }

  const [anchorEl, setAnchorEl] = useState({
    refresh: null,
    delete: null
  });

  const [actionId, setActionId] = useState(null);
  const open = {
    refresh: Boolean(anchorEl.refresh),
    delete: Boolean(anchorEl.delete)
  };

  const getStatusIcon = data => {
    let icon;
    const getToolTipText = obj => {
      return (
        <Text bold color={themeColors.dark}>
          {`${obj.type || ''} ${moment(obj.updatedAt).fromNow()}`}
          {(obj.tracebackMsg !== '' || obj.message !== '') && (
            <Text color={themeColors.dark}>
              {obj.tracebackMsg || obj.message}
            </Text>
          )}
        </Text>
      );
    };

    switch (data.status) {
      case 'error':
        icon = <ErrorOutlineIcon style={{ color: themeColors.error }} />;
        break;
      case 'done':
        icon = (
          <CheckCircleOutlineIcon style={{ color: themeColors.success }} />
        );
        break;

      case 'success':
        if (!data.tracebackMsg) {
          icon = (
            <CheckCircleOutlineIcon style={{ color: themeColors.success }} />
          );
          break;
        }
      // falls through
      case 'partial':
        icon = <Icon icon="computePartial" />;
        break;
      case 'queued':
      case 'inprogress':
      case 'inProgress':
      case 'In progress':
        icon = <QueryBuilderIcon style={{ color: themeColors.funnel }} />;
        break;
      default:
        return <></>;
    }

    return (
      <Item flex>
        <Tooltip title={getToolTipText(data)}>{icon}</Tooltip>
      </Item>
    );
  };

  const handleReshreshClickRefresh = (event, id) => {
    setActionId(id);
    setAnchorEl({
      ...anchorEl,
      refresh: anchorEl.refresh ? null : event.currentTarget
    });
  };

  const handleReshreshClickDelete = (event, id) => {
    setActionId(id);
    setAnchorEl({
      ...anchorEl,
      delete: anchorEl.delete ? null : event.currentTarget
    });
  };

  const getActions = item => {
    const {
      _id,
      onDelete: customDelete,
      onRefresh: customRefresh,
      onListLogs: customListLogs,
      status,
      customAction
    } = item;
    const deleteFct = onDelete || customDelete;
    const refreshFct = onRefresh || customRefresh;
    const listLogsFct = onListLogs || customListLogs;
    return (
      <Container>
        <Row spacing={0}>
          <Item justify="flex-end">
            {status && status.length !== 0 && getStatusIcon(status)}
            {customAction && customAction}
            {listLogsFct && (
              <IconButton onClick={() => listLogsFct(item)}>
                <ListIcon style={{ color: themeColors.light }} />
              </IconButton>
            )}

            {onUpdate && (
              <IconButton
                onClick={() => {
                  onUpdate(_id);
                }}
              >
                <CreateIcon style={{ color: themeColors.light }} />
              </IconButton>
            )}
            {onView && (
              <IconButton
                onClick={() => {
                  onView(_id);
                }}
              >
                <VisibilityIcon style={{ color: themeColors.light }} />
              </IconButton>
            )}
            {onNoteAdd && (
              <IconButton
                onClick={() => {
                  onNoteAdd(_id);
                }}
              >
                <NoteAddIcon style={{ color: themeColors.light }} />
              </IconButton>
            )}
            {typeof onSendMail === 'function' && (
              <IconButton
                onClick={() => {
                  onSendMail(_id);
                }}
              >
                <MailOutlineIcon style={{ color: themeColors.light }} />
              </IconButton>
            )}
            {typeof deleteFct === 'function' && (
              <IconButton
                onClick={() => {
                  deleteFct(_id, item);
                }}
              >
                <DeleteIcon style={{ color: themeColors.light }} />
              </IconButton>
            )}
            {Array.isArray(deleteFct) === true && (
              <>
                <IconButton
                  onClick={event => handleReshreshClickDelete(event, _id)}
                >
                  <DeleteIcon style={{ color: themeColors.light }} />
                </IconButton>
                <Popper
                  open={open.delete}
                  anchorEl={anchorEl.delete}
                  placement="bottom-end"
                  style={{ maxWidth: '320px' }}
                >
                  <Paper elevation={1}>
                    <Row spacing={0}>
                      {deleteFct.map(el => (
                        <Item>
                          <Button
                            onClick={() => {
                              setAnchorEl({ ...anchorEl, delete: null });
                              setActionId(null);
                              el.onClick(
                                actionId,
                                rows.find(e => e._id === actionId)
                              );
                            }}
                            upperCase={false}
                          >
                            {el.label}
                          </Button>
                        </Item>
                      ))}
                    </Row>
                  </Paper>
                </Popper>
              </>
            )}
            {refreshFct?.length > 0 && (
              <>
                <IconButton
                  onClick={event => handleReshreshClickRefresh(event, _id)}
                >
                  <RefreshIcon style={{ color: themeColors.light }} />
                </IconButton>
                <Popper
                  open={open.refresh}
                  anchorEl={anchorEl.refresh}
                  placement="bottom-end"
                  style={{ maxWidth: '320px' }}
                >
                  <Paper elevation={1}>
                    <Row spacing={0}>
                      {refreshFct.map(el => (
                        <Item>
                          <Button
                            onClick={() => {
                              setAnchorEl({ ...anchorEl, refresh: null });
                              setActionId(null);
                              el.onClick(actionId);
                            }}
                            upperCase={false}
                          >
                            {el.label}
                          </Button>
                        </Item>
                      ))}
                    </Row>
                  </Paper>
                </Popper>
              </>
            )}
          </Item>
        </Row>
      </Container>
    );
  };

  const newRows = rows.map(item => {
    const newItem = item;
    newItem.IconsAction = getActions(item);
    if (newItem.subRows) {
      newItem.subRows = newItem.subRows.map(subRow => {
        return { ...subRow, IconsAction: getActions(subRow) };
      });
    }
    return newItem;
  });

  return (
    <Table
      rows={newRows}
      noHeader={[...noHeader, 'IconsAction']}
      headers={newHeader}
      {...rest}
    />
  );
};

TableData.defaultProps = {
  headers: null,
  onDelete: null,
  onListLogs: null,
  onUpdate: null,
  onView: null,
  onNoteAdd: null,
  onRefresh: null,
  onSendMail: null,
  noHeader: []
};

TableData.propTypes = {
  onUpdate: PropTypes.func,
  onView: PropTypes.func,
  onNoteAdd: PropTypes.func,
  onListLogs: PropTypes.func,
  noHeader: PropTypes.arrayOf(PropTypes.bool),
  onRefresh: PropTypes.arrayOf(
    PropTypes.objectOf({ label: PropTypes.string, onClick: PropTypes.func })
  ),
  onDelete:
    PropTypes.func ||
    PropTypes.arrayOf(
      PropTypes.objectOf({ label: PropTypes.string, onClick: PropTypes.func })
    ),
  onSendMail: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  headers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

export default TableData;

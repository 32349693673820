import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

import { withTheme } from 'contexts/ThemeContext';

import { fontSizes } from 'utils/constants';

const DivWrapper = ({ themeColors, ...rest }) => {
  return <div {...rest} />;
};

DivWrapper.defaultProps = {
  themeColors: {}
};

DivWrapper.propTypes = {
  themeColors: PropTypes.objectOf(PropTypes.any)
};

export const DropdownStyleContainer = styled(DivWrapper)`
  background-color: transparent;
  font-size: ${fontSizes.medium};
  border: 1px solid ${({ themeColors }) => themeColors.activation || 'black'};
  border-radius: 4px;
  margin-right: 5px;
  padding-bottom: 2px;
  .drop-elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 200px;
  }
`;

export default withTheme(DropdownStyleContainer);

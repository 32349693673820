import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select as SelectMui } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useTheme } from 'contexts/ThemeContext';

import { Text, Tooltip } from 'components/DataDisplay';
import { Button, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { Accordion } from 'components/Surfaces';
import pluralize from 'utils/pluralize';

import { Checkbox } from './Checkbox';

const CustomOption = ({
  datas: { items = [], key, label },
  onChange,
  addAll,
  removeAll,
  selectedItems = []
}) => {
  const { panelColor, themeColors } = useTheme();
  const colorRules = themeColors[panelColor];

  const deepCountSelected = datas => {
    if (!datas?.items?.length) {
      const isSelected = selectedItems.findIndex(el => el.key === datas.key);
      return isSelected !== -1 ? 1 : 0;
    }

    return datas.items.reduce((acc, item) => {
      return acc + deepCountSelected(item);
    }, 0);
  };

  const deepItems = data => {
    // eslint-disable-next-line no-shadow
    const { key, label, items } = data;
    if (!items?.length) {
      return [{ key, label }];
    }

    return items.reduce((acc, item) => {
      return [...acc, ...deepItems(item)];
    }, []);
  };

  const onAllChange = list => {
    const notSelected = list.filter(
      el =>
        !selectedItems.find(
          // eslint-disable-next-line no-shadow
          ({ key, label }) => key === el.key && label === el.label
        )
    );
    if (notSelected.length) {
      addAll(notSelected);
    } else {
      removeAll(list);
    }
  };

  const isSelected = selectedItems.findIndex(el => el.key === key) !== -1;
  const nbSelected = deepCountSelected({ items, key, label });

  const itemsList = items.reduce((acc, item) => {
    return [...acc, ...deepItems(item)];
  }, []);

  return items.length !== 0 ? (
    <Row justify="flex-start" spacing={0}>
      <Item>
        <Accordion
          rows={[
            {
              key,
              label: (
                <Row alignItems="center" spacing={0} justify="flex-start">
                  <Item
                    flex
                    style={{
                      marginRight: '5px'
                    }}
                  >
                    <Checkbox
                      color={colorRules}
                      checked={nbSelected === itemsList.length}
                      onChange={() => onAllChange(itemsList)}
                    />
                  </Item>
                  <Item xs justify="flex-start">
                    <Tooltip title={label}>
                      <Text fontSize="13px" nowrap>
                        {label}
                      </Text>
                    </Tooltip>
                  </Item>
                  {nbSelected !== 0 && (
                    <Item xs={12} justify="flex-end">
                      <Text color={colorRules}>{` (${nbSelected})`}</Text>
                    </Item>
                  )}
                </Row>
              ),
              items: items.map((el, index) => {
                const customKey = `${el.key}_${index}`;
                return (
                  <CustomOption
                    key={customKey}
                    datas={el}
                    addAll={addAll}
                    removeAll={removeAll}
                    onChange={onChange}
                    selectedItems={selectedItems}
                  />
                );
              })
            }
          ]}
        />
      </Item>
    </Row>
  ) : (
    <Row
      alignItems="center"
      justify="flex-start"
      spacing={0}
      style={{ padding: '0px 9px' }}
    >
      <Item flex>
        <Checkbox
          color={colorRules}
          checked={isSelected}
          onChange={() => onChange({ key, label })}
        />
      </Item>
      <Item xs justify="flex-start">
        <Tooltip title={label || key}>
          <Text fontSize="13px" nowrap>
            {label || key}
          </Text>
        </Tooltip>
      </Item>
    </Row>
  );
};

export const CustomSelect = props => {
  const {
    title,
    required,
    disabled,
    color,
    onChange,
    options,
    selected = [],
    small,
    bgColor,
    selectAll,
    searchBar,
    noneIsAll,
    hideNone,
    filterOthers,
    dynamicList,
    width,
    limit = 20,
    type,
    textColor
  } = props;

  const { panelColor, themeColors } = useTheme();
  const [selectedItems, setSelectedItems] = useState(selected);
  const [open, setOpen] = useState(false);
  const [optionsDisp, setOptionsDisp] = useState([]);
  const [initiated, setInitiated] = useState(false);
  const [dynamicOptions, setDynamicOptions] = useState(options);
  const [searched, setSearch] = useState('');
  const colorRules = themeColors[color] || color || themeColors[panelColor];
  const selectAllDataKey = 'selectAll';

  const selectAllData = {
    key: selectAllDataKey,
    label: 'Tout sélectionner'
  };
  const newOptions = [];
  if (!hideNone) {
    newOptions.push({
      label: filterOthers,
      key: null
    });
  }

  const allOptions = newOptions.concat(options);

  const verifSelectAll = el => el.key === selectAllDataKey;

  const getAllItems = items => {
    return items.reduce((acc, el) => {
      if (el?.items?.length > 0) {
        acc.push(...getAllItems(el.items));
      } else {
        acc.push(el);
      }
      return acc;
    }, []);
  };

  const allIsSelected = selItems => {
    if (!selectAll) {
      return false;
    }
    const opt = getAllItems(optionsDisp);
    const tmp = selItems.filter(el => opt.find(e => el.key === e.key));
    return tmp.length === opt.length;
  };

  const useStyles = makeStyles({
    root: {
      // '& label.Mui-focused': {
      //   color: themeColors.activation
      // },
      '& .MuiInputBase-root': {
        color: themeColors.light,
        backgroundColor: bgColor || 'transparent'
      },
      '& .MuiSelect-icon': {
        color: themeColors.light
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colorRules
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: colorRules
        },
        '&:hover fieldset': {
          borderColor: themeColors.light
        },
        '&.Mui-focused fieldset': {
          borderColor: colorRules
        }
      }
    }
  });

  const classes = useStyles();

  // eslint-disable-next-line consistent-return
  const onCheck = ({ key, label }) => {
    const tmp = [...selectedItems];
    const existIndex = selectedItems.findIndex(el => el.key === key);
    if (existIndex !== -1) {
      return setSelectedItems(
        tmp.filter(el => !(el.key === key) && !verifSelectAll(el))
      );
    }
    tmp.push({ key, label });
    if (allIsSelected(tmp)) {
      tmp.push(selectAllData);
    }
    setSelectedItems(tmp);
  };

  const addAll = list => {
    const tmp = [...selectedItems, ...list];
    if (allIsSelected(tmp)) {
      tmp.push(selectAllData);
    }
    setSelectedItems(tmp);
  };

  const removeAll = list => {
    const tmp = selectedItems.filter(
      el => !list.find(({ key }) => key === el.key || verifSelectAll(el))
    );
    setSelectedItems(tmp);
  };

  const keepItemSelected = opt => {
    const rslt = selectedItems.reduce((acc, el) => {
      if (!opt.find(e => e.key === el.key) && !verifSelectAll(el)) {
        acc.push(el);
      }
      return acc;
    }, []);
    return rslt;
  };

  const getAllItemsBySearch = (items, sW) => {
    const purify = str => {
      return typeof str === 'string'
        ? str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        : '';
    };

    const regex = '[A-Za-zÀ-ÖØ-öø-ÿ0-9]+';
    const tbExp = sW.match(new RegExp(regex, 'gi')) || [];

    const result = items
      .filter(o => !o[selectAllDataKey])
      .filter(item => {
        for (let i = 0; i < tbExp.length; i += 1) {
          const searchedWord = purify(tbExp[i].toUpperCase());
          if (!new RegExp(searchedWord, 'gi').test(item.label)) {
            return false;
          }
        }
        return true;
      });
    return result;
  };

  const onSearch = value => {
    setSearch(value);
    const result = getAllItemsBySearch(allOptions, value);
    setOptionsDisp(result);
  };

  const onSelectAll = ({ key, label }) => {
    const existIndex = selectedItems.findIndex(el => el.key === key);
    if (existIndex !== -1) {
      return setSelectedItems(keepItemSelected(getAllItems(optionsDisp)));
    }
    return setSelectedItems([
      { key, label },
      ...selectedItems,
      ...getAllItems(optionsDisp)
    ]);
  };

  const handleData = () => {
    if (options.length) {
      setDynamicOptions(allOptions);

      const sWLenth = searched.length;

      if (!sWLenth) {
        setOptionsDisp(allOptions);
        setInitiated(true);
      }

      const optionsToHandle =
        dynamicList || !open ? dynamicOptions : optionsDisp;

      const selectedLength = selectedItems.filter(
        o => !o.key === selectAllDataKey || o.key === selectAllDataKey
      ).length;

      const isAllChecked =
        selectedLength === optionsToHandle.length || selectedLength;

      const purgedSelection = selectedItems.reduce((acc, value) => {
        if (allOptions.find(e => e.key === value.key)) acc.push(value);
        return acc;
      }, []);

      const finalSelection =
        (isAllChecked ||
          (noneIsAll && !selectedItems.length && !initiated) ||
          selectedItems.length === allOptions.length) &&
        !sWLenth
          ? [selectAllData, ...optionsDisp]
          : purgedSelection;

      setSelectedItems(finalSelection);
    }
  };
  useEffect(() => {
    setSelectedItems(options.filter(option => selected.includes(option.key) || selected.map(el => el.key).includes(option.key)));

    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    handleData();

    // eslint-disable-next-line
  }, [options, open, searched]);

  return (
    <FormControl
      size="small"
      variant="outlined"
      className={classes.root}
      style={{
        padding: small && '6px 0px',
        width: '100%'
      }}
    >
      {title && (
        <Row spacing={2} justify="flex-start" alignItems="center">
          <Item justify="flex-start" flex>
            <Text noWrap fontSize="14px">
              {title === '&nbsp;' ? <>&nbsp;</> : title}
              {required && '*'}
            </Text>
          </Item>
        </Row>
      )}
      <SelectMui
        disabled={disabled}
        style={{
          height: small && '40px'
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        multiple
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {
          setOpen(true);
        }}
        renderValue={() => (
          <Text
            nowrap
            style={{
              maxWidth: '200px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              textAlign: 'justify'
            }}
          >
            {selectedItems.length === allOptions.length ||
            selectedItems.find(o => o.key === selectAllDataKey)
              ? 'Tous'
              : selectedItems
                  .reduce(
                    (acc, e) =>
                      e.key !== selectAllDataKey ? acc.concat(e.label) : acc,
                    []
                  )
                  .join(', ')}
          </Text>
        )}
        value={selectedItems}
      >
        <Container style={{ width }} spacing={0}>
          {searchBar && (
            <TextField
              width="95%"
              placeholder="Rechercher..."
              fontSize="smaller"
              clearable
              small
              value={searched}
              onChange={onSearch}
              color={themeColors.themeV2.inputColor}
            />
          )}
          <Row
            spacing={0}
            style={{
              maxHeight: '300px',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            {selectAll && !searched.length && (
              <CustomOption
                datas={selectAllData}
                onChange={onSelectAll}
                selectedItems={selectedItems}
              />
            )}
            {optionsDisp
              .slice(0, limit ? limit : optionsDisp.length)
              .map((option, index) => {
                return (
                  <CustomOption
                    key={option.id}
                    datas={option}
                    onChange={onCheck}
                    addAll={addAll}
                    removeAll={removeAll}
                    selectedItems={selectedItems}
                  />
                );
              })}
            {limit && limit < optionsDisp.length && (
              <Text style={{ display: 'flex' }}>
                +<Text color={textColor}>{optionsDisp.length - limit}</Text>
                &nbsp;{pluralize(type, optionsDisp.length - limit)}
                &nbsp;restantes
              </Text>
            )}
          </Row>
          <Row>
            <Item>
              <Button
                variant="contained"
                small
                onClick={() => {
                  setOpen(false);
                  onChange(selectedItems);
                }}
              >
                Valider
              </Button>
            </Item>
          </Row>
        </Container>
      </SelectMui>
    </FormControl>
  );
};

CustomSelect.defaultProps = {
  color: null,
  onChange: () => null,
  options: [],
  small: false,
  bgColor: null,
  selected: null,
  width: '280px',
  title: null,
  required: null,
  disabled: false,
  selectAll: false,
  noneIsAll: false,
  searchBar: false,
  filterOthers: 'Aucune',
  dynamicList: false
};
CustomSelect.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.shape(),
  small: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.string),
  bgColor: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  selectAll: PropTypes.bool,
  noneIsAll: PropTypes.bool,
  searchBar: PropTypes.bool,
  filterOthers: PropTypes.string,
  dynamicList: PropTypes.bool
};

export default CustomSelect;

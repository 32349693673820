import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import moment from 'moment';

import { useTheme } from 'contexts/ThemeContext';

import { spotHitErrors } from 'utils/constants/spotHitErrors';

import { Text } from 'components/DataDisplay';
import { Modal } from 'components/Feedback';
import { Container, Item, Row } from 'components/Layout';

const statusMapping = themeColors => ({
  uploadingSegments: {
    message: 'Setup en cours: uploadingSegments',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  segmentsExported: {
    message: 'Setup en cours: segmentsExported',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  uploadingCreatives: {
    message: 'Setup en cours: uploadingCreatives',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  creativesUploaded: {
    message: 'Setup en cours: creativesUploaded',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  SettingUpCampaign: {
    message: 'Setup en cours: SettingUpCampaign',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  todo: {
    message: 'Setup en cours',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  toDo: {
    message: 'Setup en cours',
    icon: <QueryBuilderIcon style={{ color: themeColors.funnel }} />
  },
  done: {
    message: 'Setup terminé',
    icon: <CheckCircleOutlineIcon style={{ color: themeColors.success }} />
  },
  error: {
    message: 'Erreur lors du Setup',
    icon: <ErrorOutlineIcon style={{ color: themeColors.error }} />
  },
  aborted: {
    message: 'Setup avorté manuellement',
    icon: <ErrorOutlineIcon style={{ color: themeColors.error }} />
  }
});

const ModalLogs = props => {
  const { themeColors } = useTheme();
  const { name, title = 'Mises à jour des setup', datas = [], onClose } = props;

  const [selected, setSelected] = React.useState(0);
  const [detailsOpen, setDetailsOpen] = React.useState([]);

  const details = ['error', 'error_retry', 'partial', 'partial_retry'];

  const processErrorMessage = message => {
    if (message.includes('SpotHit')) {
      const errorMatch = message.match(/"erreurs":"([^"]+)"/);
      if (errorMatch) {
        const errorCodesString = errorMatch[1];
        const codes = errorCodesString.split(',');
        const descriptions = codes.map(code => {
          const trimmedCode = code.trim();
          const errorObj = spotHitErrors.find(
            err => err.errorCode.toString() === trimmedCode
          );
          const description = errorObj
            ? errorObj.description
            : 'Erreur non connue';
          return `Erreur SpotHit: ${trimmedCode}, description: ${description}`;
        });
        return descriptions;
      }
    }
    return [message];
  };

  const onClick = value => {
    setSelected(value);
  };
  return (
    <Modal
      titleHead={`${title} : ${name}`}
      maxWidth="lg"
      open
      onClose={onClose}
    >
      <Container>
        {datas.length > 1 && (
          <Row>
            {datas.map((data, index) => (
              <Item
                flex
                style={{ padding: '10px' }}
                onClick={() => onClick(index)}
              >
                <Text
                  link
                  fontSize="18px"
                  fontWeight={selected === index ? 700 : 400}
                  style={{
                    borderBottom:
                      selected === index
                        ? `3px solid ${themeColors.activation}`
                        : null
                  }}
                >
                  {data.label}
                </Text>
              </Item>
            ))}
          </Row>
        )}
        <Row>
          <Item xs={4} justify="flex-start">
            <Text color={themeColors.activation}>CampaignSetupTaskId</Text>
          </Item>
          <Item xs={4} justify="flex-start">
            <Text color={themeColors.activation}>Status</Text>
          </Item>
          <Item xs={4}>
            <Row justify="space-between" spacing={0}>
              <Item xs={12} justify="flex-start">
                <Text color={themeColors.activation}>Date & heure</Text>
              </Item>
            </Row>
          </Item>
        </Row>
        <Row spacing={0}>
          {datas[selected].values
            .sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
            .map(e => {
              const idKey = e._id ? '_id' : 'id';
              const isDetailsOpen = detailsOpen.find(
                d => d[idKey] === e[idKey]
              );
              return (
                <Item
                  style={{
                    margin: 8,
                    padding: '10px 15px',
                    borderRadius: 10,
                    backgroundColor: themeColors.primary
                  }}
                  alignItems="center"
                >
                  <Row spacing={0}>
                    <Item xs={4}>
                      <Row spacing={0}>
                        <Item xs={12} justify="flex-start">
                          <Row spacing={0} justify="flex-start">
                            <Item justify="flex-start">
                              <Text>{e._id}</Text>
                            </Item>
                          </Row>
                        </Item>
                      </Row>
                    </Item>
                    <Item xs={4}>
                      <Row spacing={0}>
                        <Item xs={2} justify="flex-start">
                          {statusMapping(themeColors)[e.status]?.icon}
                        </Item>
                        <Item xs={10} justify="flex-start">
                          {details.indexOf(e.status) !== -1 ? (
                            <Row spacing={0} justify="flex-start">
                              <Item justify="flex-start">
                                <Text>
                                  {statusMapping(themeColors)[e.status].message}
                                </Text>
                              </Item>
                              <Item justify="flex-start">
                                <Text
                                  onClick={() => {
                                    if (isDetailsOpen) {
                                      setDetailsOpen(
                                        detailsOpen.filter(d => d._id !== e._id)
                                      );
                                    } else {
                                      setDetailsOpen([...detailsOpen, e]);
                                    }
                                  }}
                                  bold
                                  color={themeColors.funnel}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {isDetailsOpen
                                    ? 'masquer le rapport'
                                    : 'voir le rapport'}
                                </Text>
                              </Item>
                            </Row>
                          ) : (
                            <Text>
                              {statusMapping(themeColors)[e.status].message}
                            </Text>
                          )}
                        </Item>
                      </Row>
                    </Item>
                    <Item xs={4}>
                      <Row spacing={0}>
                        <Item xs={12} justify="flex-start">
                          <Text bold color={themeColors.activation}>
                            {moment(e.updatedAt).fromNow()}
                          </Text>
                        </Item>
                      </Row>
                    </Item>
                    {isDetailsOpen && (
                      <Item>
                        <Row
                          spacing={1}
                          style={{
                            backgroundColor: themeColors.light,
                            margin: 5,
                            borderRadius: 10
                          }}
                        >
                          {e?.errorMsg?.split('|').map(message => (
                            <Item justify="flex-start">
                              {processErrorMessage(message).map(line => (
                                <Text color={themeColors.keep_light}>
                                  {line}
                                </Text>
                              ))}
                            </Item>
                          ))}
                        </Row>
                      </Item>
                    )}
                  </Row>
                </Item>
              );
            })}
        </Row>
      </Container>
    </Modal>
  );
};

ModalLogs.defaultProps = {};
ModalLogs.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ModalLogs;
